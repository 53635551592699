import React, { ComponentProps, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { IResourceComponentsProps, useOne, useUpdate } from "@refinedev/core";

import {
    List,
    useEditableTable,
    DateField,
    TagField,
    ShowButton,
    SaveButton,
    EditButton,
    useModalForm,
    TextField,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Button, Form, Input, Spin, Radio, Modal, Tooltip, Select, Tag, Checkbox, SelectProps } from "antd";

import { ITransaction } from "interfaces";
import { useAccount } from 'wagmi'
import Typography from 'antd/lib/typography';

import {
    AppstoreOutlined,
    SearchOutlined,
    SettingOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";

import debounce from 'lodash/debounce';

interface ITag {
    name : string
    color: string
  }

interface IGroupname{
    name : string
    color: string
}

interface IAddressLabel{
    id: string
    name : string
    color: string
}

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

export const TransactionsList: React.FC<IResourceComponentsProps> = () => {
    const { address } = useAccount()
    const navigate = useNavigate();

    const { mutate } = useUpdate();
    const cancelRef = useRef<(() => void) | null>(null);
    const cancelDelete = () => {
        cancelRef.current?.();
    };

    function DebounceSelect<
        ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
        >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
        const [fetching, setFetching] = useState(false);
        const [options, setOptions] = useState<ValueType[]>([]);
        const fetchRef = useRef(0);

        const debounceFetcher = useMemo(() => {
            const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
            };

            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
            <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
            />
        );
    }

    async function fetchSearchList(searchString: string) {
        let searchOptions = []

        let transaction_hash : SearchValue = {
            label: "transaction_hash=" + searchString,
            value: "transaction_hash_filter",
        }

        let tag : SearchValue = {
            label: "tag=" + searchString,
            value: "tag_filter",
        }

        let label : SearchValue = {
            label: "label=" + searchString,
            value: "label_filter",
        }

        let address : SearchValue = {
            label: "address=" + searchString,
            value: "address_filter",
        }

        let chain : SearchValue = {
            label: "chain=" + searchString,
            value: "chain_filter",
        }

        let groupName : SearchValue = {
            label: "group_name=" + searchString,
            value: "group_filter",
        }

        let status : SearchValue = {
            label: "status=" + searchString,
            value: "status_filter",
        }

        let decoded_call : SearchValue = {
            label: "function_call=" + searchString,
            value: "decoded_call_filter",
        }

        let include_id : SearchValue = {
            label: "include_id=" + searchString,
            value: "include_id_filter",
        }


        searchOptions.push(transaction_hash)
        searchOptions.push(tag)
        searchOptions.push(label)
        searchOptions.push(address)
        searchOptions.push(chain)
        searchOptions.push(groupName)
        searchOptions.push(status)
        searchOptions.push(decoded_call)
        searchOptions.push(include_id)

        return searchOptions
    }

    // Usage of DebounceSelect
    interface SearchValue {
        label: string;
        value: string;
    }

    const [value, setValue] = useState<SearchValue>();
    const [label, setLabel] = useState<string>();

    const [transactionsResult, setTransactionsResult] = useState<any>([]);

    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    async function searchWithFilter(searchValues: SearchValue[]) {
        console.log(searchValues)
        setSearchLoading(true)

        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        
        const url = "https://" + continent.toLowerCase() + ".api." + domain + "/private/GET/" + continent.toUpperCase() + "/v0/transactions?limit=" + limit + "&search=" + searchValues[0].label + "&last_evaluated_key=null" // no lastEvaluatedKey with filter search 

        console.log(url)
        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };
    
        return fetch(url, {headers: requestHeaders})
          .then((response) => {
              // Inspect the headers in the response
              response.headers.forEach((value: any, key: string) => {
                if(key == "x-last-evaluated-key"){
                    console.log(value)
                    console.log(key)
                    let last_evaluated_key = value ? value : "null"

                    setLastEvaluatedKey(last_evaluated_key)  
                }
              });

              return response   
            }
          )
          .then((response) => 
            response.json()            
          )
          .then((body) => {
            console.log(body)
            setTransactionsResult(body.result)
            setSearchLoading(false)
          }
          );
    }

    const [historicEvaluatedKey, setHistoricEvaluatedKey] = useState<Array<string>>([])
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null>(null)
    
    const handleLastEvaluatedKey = (e:any) => {

        let last_evaluated_key = tableQueryResult.data?.last_evaluated_key ? tableQueryResult.data?.last_evaluated_key : "null"

        if(!e.target.attributes.disabled && tableQueryResult.data?.last_evaluated_key){
            setLastEvaluatedKey(last_evaluated_key)  
        }

    };
    
    const handlePrevEvaluatedKey = (e:any) => {
        let new_current = current - 2

        if(!e.target.attributes.disabled){
            if(new_current == 0 ){
                setLastEvaluatedKey("null")
            }else{
                setLastEvaluatedKey(historicEvaluatedKey[new_current])
            }
        }       
    };

    const [limit, setLimit] = useState(0)

    const {
        tableProps,
        formProps,
        tableQueryResult,
        tableQueryResult: { refetch },
        searchFormProps,
        pageSize,
        setCurrent,
        setPageSize,
        current,
        isEditing, setId:
        setEditId, saveButtonProps,
        cancelButtonProps,
        editButtonProps,
        formLoading
    } = useEditableTable<ITransaction>({
        meta: { 
            "last_evaluated_key": lastEvaluatedKey,
            "limit": limit,
            "search": label ? label : "null"
        },
    });

    const onFinishHandlerEditTransactions = (values: any) => {
        console.log("Hello on finish")
        console.log(values)

        // onFinish?.({
        //     address: `${values.address}`,
        //     chain: `${values.chain}`,
        //     signature: signature,
        //     status: `${values.status}`,
        //     version: `${values.version}`,
        //     permission_document: permission_document,
        // });

        onFinish(values);
        // close();
    };

    // Create Display columns Modal
    const {
        modalProps: createColumnsModalProps,
        formProps: createColumnsFormProps,
        show: createColumnsModalShow,
        formLoading: createColumnsFormLoading,
        onFinish,
        close,
    } = useModalForm({
        warnWhenUnsavedChanges: false,
        action: "create",
        syncWithLocation: false,
        resource: "Show Columns",

    });

    const onFinishHandler = (values: any) => {
        console.log("finish show column modal clicked")
        close();
    };

    // Import Transaction Modal
    const {
        modalProps: importTransactionModalProps,
        formProps: importTransactionFormProps,
        show: importTransactionModalShow, 
        formLoading: importTransactionFormLoading,
    } = useModalForm({
        warnWhenUnsavedChanges: false,
        action: "create",
        syncWithLocation: false,
        resource: "transactions-import",

    });

    const [origionalColumnTitles, setOrigionalColumnTitles] = useState<Array<{
        hidden: boolean,
        position: string,
        title: string,
        dataIndex: string,
        render: any
    }>
    >([])

    const [groupNames, setGroupNames] = useState<Array<IGroupname>
    >([])

    const [isEditingGroupName, setIsEditingGroupName] = useState(false)

    const [isEditingFromAddressLabel, setIsEditingFromAddressLabel] = useState(false)
    const [fromAddressLabels, setFromAddressLabels] = useState<Array<IAddressLabel>
    >([])

    const [isEditingToAddressLabel, setIsEditingToAddressLabel] = useState(false)
    const [toAddressLabels, setToAddressLabels] = useState<Array<IAddressLabel>
    >([])

    const [tags, setTags] = useState<Array<ITag>
    >([])

    const [isEditingTag, setIsEditingTag] = useState(false)

    function inputKeyDownFromAddressLabels(e: any) {
        const val = e.target.value;

        if (e.key === 'Enter' && val) {
            console.log("enter")
            if (fromAddressLabels.find(from_address_label => from_address_label.name === val.toLowerCase())) {
                setNewFromAddressLabelValue("")
                return;
            }

            const from_address_label: IAddressLabel = { id: "", name: val, color: "808080" };

            setFromAddressLabels(prevState => [...prevState, from_address_label]);
            console.log(fromAddressLabels)
            setNewFromAddressLabelValue("")
        }
    }

    function removeFromAddressLabel(from_address_label: IAddressLabel) {
        const index = fromAddressLabels.indexOf(from_address_label, 0);
        console.log(fromAddressLabels)
        console.log(from_address_label)
        if (index > -1) {
            fromAddressLabels.splice(index, 1);
            console.log(fromAddressLabels)
            setFromAddressLabels(fromAddressLabels)
        }
    }

    function inputKeyDownToAddressLabels(e: any) {
        const val = e.target.value;

        if (e.key === 'Enter' && val) {
            console.log("enter")
            if (toAddressLabels.find(to_address_label => to_address_label.name === val.toLowerCase())) {
                setNewToAddressLabelValue("")
                return;
            }
            
            const to_address_label: IAddressLabel = { id: "", name: val, color: "808080" };

            setToAddressLabels(prevState => [...prevState, to_address_label]);
            console.log(toAddressLabels)

            setNewToAddressLabelValue("")
        }
    }

    function removeToAddressLabel(to_address_label: IAddressLabel) {
        const index = toAddressLabels.indexOf(to_address_label, 0);
        console.log(toAddressLabels)
        console.log(to_address_label)
        if (index > -1) {
            toAddressLabels.splice(index, 1);
            console.log(toAddressLabels)
            setToAddressLabels(toAddressLabels)
        }
    }

    function inputKeyDownTags(e: any) {
        const val = e.target.value;

        if (e.key === 'Enter' && val) {
            console.log("enter")
            if (tags.find(tag => tag.name === val.toLowerCase())) {
                setNewTagValue("")
                return;
            }
            
            const tag: ITag = { name: val, color: "808080" };

            setTags(prevState => [...prevState, tag]);
            console.log(tags)
            setNewTagValue("")
        }
    }

    function removeTag(tag: ITag) {
        const index = tags.indexOf(tag, 0);
        console.log(tags)
        console.log(tag)
        if (index > -1) {
            tags.splice(index, 1);
            console.log(tags)
            setTags(tags)
        }
    }

    function inputKeyDownGroupName(e: any) {
        const val = e.target.value;

        if (e.key === 'Enter' && val) {
            console.log("enter")
            if (groupNames.find(group_name => group_name.name === val.toLowerCase())) {
                setNewGroupNameValue("")
                return;
            }
            
            const group_name: IGroupname = { name: val, color: "808080" };

            setGroupNames(prevState => [...prevState, group_name]);
            setNewGroupNameValue("")
        }
    }

    function removeGroupName(group_name: IGroupname) {
        const index = groupNames.indexOf(group_name, 0);
        if (index > -1) {
            groupNames.splice(index, 1);
            console.log(groupNames)
            setGroupNames(groupNames)
        }
    }

    function changeOrigionalColumnTitles(columns: any[]) {
        if(origionalColumnTitles.length == 0){
            let displayColumns = localStorage.getItem('transactionDisplayColumns') ? JSON.parse(localStorage['transactionDisplayColumns']) : columns
            // https://github.com/jbetancur/react-data-table-component/issues/708
            let columnTitles: any = []
    
            for (var column of columns) {
                let columnIsAlsoInDisplayColumns = false
                for (var displayColumn of displayColumns) {
                    if (column.title == displayColumn.title) {
                        column.hidden = displayColumn.hidden
                        columnIsAlsoInDisplayColumns = true
                        columnTitles.push(column)
                        break
                    }
                }
                if (columnIsAlsoInDisplayColumns == false){
                    column.hidden = true
                    columnTitles.push(column)
                }
            }
    
            setOrigionalColumnTitles(columnTitles);
        }

        return true
    }

    const [newFromAddressLabelValue, setNewFromAddressLabelValue] = useState("")
    function onChangeFromAddressLabelValue(e: any) {
        setNewFromAddressLabelValue(e.target.value)
    }
   
    const [newToAddressLabelValue, setNewToAddressLabelValue] = useState("")
    function onChangeToAddressLabelValue(e: any) {
        setNewToAddressLabelValue(e.target.value)
    }

    const [newGroupNameValue, setNewGroupNameValue] = useState("")
    function onChangeGroupNameValue(e: any) {
        setNewGroupNameValue(e.target.value)
    }

    const [newTagValue, setNewTagValue] = useState("")
    function onChangeTagValue(e: any) {
        setNewTagValue(e.target.value)
    }

    function selectColor(colorNum:any, colors:any){
        if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
        return "hsl(" + (colorNum * (360 / colors) % 360) + ",100%,50%)";
    }
    


    const columns: any = [
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            title: 'Hash',
            key: 'hash',
            dataIndex: 'hash',
            render: (_: any, record: any) => (
                <Tooltip title={record?.hash} ><TagField
                    key={record?.hash}
                    value={`${record?.hash.substring(0, 10)
                        }`}
                /></Tooltip>
            ),
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            title: 'Date',
            key: 'block_timestamp',
            dataIndex: 'block_timestamp',
            render: (value: any) => (
                <DateField
                    value={value}
                    format="LLL"
                />
            ),
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            title: 'Chain',
            key: 'chain',
            dataIndex: 'chain',
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            dataIndex: "from_address",
            title: "From Address",
            key: "from_address",
            render: (value: any) => {
                // let value: ComponentProps<typeof TagField>["value"];
                switch (value) {
                    case process.env.REACT_APP_PROXY_CONTRACT_ADDRESS?.toLowerCase():
                        value = "AuthE Proxy"
                        break;
                    case process.env.REACT_APP_CUSTOM_ERC20_CONTRACT_ADDRESS?.toLowerCase():
                        value = "Custom ERC20 contract";
                        break;
                    default:
                        break;
                }
                return <TagField value={value} />;
            }
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            dataIndex: "from_address_label",
            title: "From Address Label",
            key: "from_address_label",
            render: (value: any, record: any) => {
                if (isEditing(record["user_id-block_timestamp-chain_id-hash-type"])) {
                    
                    if (isEditingFromAddressLabel == false) {
                        setIsEditingFromAddressLabel(true)
                        if(value){
                            setFromAddressLabels(value)
                        }
                        else{
                            
                            setFromAddressLabels([])
                        }
                    }
                        
                    return (
                        <Form.Item
                            name="from_address_label"
                            style={{ margin: 0, width: 200, display: "flex" }}

                        >
                            <div className="container">
                                {
                                    fromAddressLabels.map((from_address_label: any) => <Tooltip title={from_address_label.is_main_item ? "Is main address label" : from_address_label.is_main_item?.startsWith("address_label") ? "Is default address label" : ""}> <TagField id={from_address_label.name} key={from_address_label.name} value={from_address_label.name} color={"#" + from_address_label.color}  onClick={(e: any) => { removeFromAddressLabel(from_address_label) }} style={{ marginTop: 5, marginBottom: 5 }}></TagField></Tooltip>)
                                }
                                 <Input id="from_address_label" onKeyDown={(e: any) => { inputKeyDownFromAddressLabels(e); }} placeholder='Add a new from address label here' />

                                {/* <Input id="from_address_label" onChange={(e) => { onChangeFromAddressLabelValue(e) }} value={newFromAddressLabelValue} onKeyDown={(e: any) => { inputKeyDownFromAddressLabels(e); }} placeholder='Add a new from address label here' /> */}
                            </div>
                        </Form.Item>
                    );
                }


                const local_from_address_labels = [];
                if (value) {
                    for (var from_address_label of record["from_address_label"]) {
                        local_from_address_labels.push( <Tooltip title={from_address_label.is_main_item ? "Is main address label" : from_address_label.is_main_item?.startsWith("address_label") ? "Is default address label" : ""}> <TagField id={from_address_label.name} key={from_address_label.name} value={from_address_label.name} color={"#" + from_address_label.color} style={{ marginTop: 10 }} /></Tooltip>)
                    }
                }
                return local_from_address_labels;
            }
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            dataIndex: "to_address",
            title: "To Address",
            key: "to_address",
            render: (value: any) => {
                // let value: ComponentProps<typeof TagField>["value"];
                switch (value) {
                    case process.env.REACT_APP_PROXY_CONTRACT_ADDRESS?.toLowerCase():
                        return "AuthE Proxy"
                    case process.env.REACT_APP_CUSTOM_ERC20_CONTRACT_ADDRESS?.toLowerCase():
                        return "Custom ERC20 contract";
                    default:
                        return <TagField value={value} />;
                }
            }
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            dataIndex: "to_address_label",
            title: "To Address Label",
            key: "to_address_label",
            render: (value: any, record: any) => {
                if (isEditing(record["user_id-block_timestamp-chain_id-hash-type"])) {
                    
                    if (isEditingToAddressLabel == false) {
                        setIsEditingToAddressLabel(true)

                        if(value){
                            setToAddressLabels(value)
                        }
                        else{
                            
                            setToAddressLabels([])
                        }
                    }
                        
                    return (
                        <Form.Item
                            name="to_address_label"
                            style={{ margin: 0, width: 200, display: "flex" }}

                        >
                            <div className="container">
                                {
                                    toAddressLabels.map((to_address_label: any) =><Tooltip title={to_address_label.is_main_item ? "Is main address label" : to_address_label.is_main_item?.startsWith("address_label") ? "Is default address label" : ""}> <TagField id={to_address_label.name} key={to_address_label.name} value={to_address_label.name} color={"#" + to_address_label.color}  onClick={(e: any) => { removeToAddressLabel(to_address_label) }} style={{ marginTop: 5, marginBottom: 5 }}></TagField></Tooltip>)
                                }
                                <Input id="to_address_label" onKeyDown={(e: any) => { inputKeyDownToAddressLabels(e); }} placeholder='Add a new to address label here' />

                                {/* <Input id="to_address_label" onChange={(e) => { onChangeToAddressLabelValue(e) }} value={newToAddressLabelValue} onKeyDown={(e: any) => { inputKeyDownToAddressLabels(e); }} placeholder='Add a new to address label here' /> */}
                            </div>
                        </Form.Item>
                    );
                }


                const local_to_address_labels = [];
                if (value) {
                    for (var to_address_label of record["to_address_label"]) {
                        local_to_address_labels.push(<Tooltip title={to_address_label.is_main_item ? "Is main address label" : to_address_label.is_main_item?.startsWith("address_label") ? "Is default address label" : ""}><TagField id={to_address_label.name} key={to_address_label.name} value={to_address_label.name} color={"#" + to_address_label.color} style={{ marginTop: 10 }} /></Tooltip>)
                    }
                }
                return local_to_address_labels;
            }
        },
        {
            randomId: "",
            editable: true,
            hidden: false,
            position: "1",
            dataIndex: "group_names",
            title: "Groups",
            key: "group_names",
            render: (value: any, record: any) => {
                if (isEditing(record["user_id-block_timestamp-chain_id-hash-type"])) {
                    if (isEditingGroupName == false) {
                        setIsEditingGroupName(true)
                        if(value){
                            setGroupNames(value)
                        }
                        else{
                            setGroupNames([])
                        }
                    }

                    

                    return (
                        <Form.Item
                            name="title"
                            style={{ margin: 0, width: 200, display: "flex" }}

                        >
                            <div className="container">
                                {groupNames.map((group_name: any) => <TagField id={group_name.name} key={group_name.name} value={group_name.name} color={"#" + group_name.color}  onClick={(e: any) => { removeGroupName(group_name) }} style={{ marginTop: 5, marginBottom: 5 }}></TagField>)}
                                <Input key="group_names" id="group_names" onKeyDown={(e: any) => { inputKeyDownGroupName(e); }} placeholder='Add a new group name here' />

                                {/* <Input key="group_names" id="group_names" onChange={(e) => { onChangeGroupNameValue(e) }} value={newGroupNameValue} onKeyDown={(e: any) => { inputKeyDownGroupName(e); }} placeholder='Add a new group name here' /> */}
                            </div>
                        </Form.Item>
                    );
                }

                const group_names = [];
                
                if (value) {


                    for (var group_name of record["group_names"]) {
                        group_names.push(<TagField id={group_name.name} key={group_name.name} value={group_name.name} color={"#" + group_name.color}  style={{ marginTop: 10 }} />)
                    }
                }
                return group_names;
            }
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            dataIndex: "receipt_status",
            title: "Status",
            key: "receipt_status",
            render: (value: any) => {
                let color: ComponentProps<typeof TagField>["color"];
                switch (value) {
                    case "1":
                        color = "success";
                        value = "Confirmed"
                        break;
                    case "approved":
                        color = "success";
                        break;
                    case "approval_executed":
                        color = "success";
                        break;
                    case "denied":
                        color = "error";
                        break;
                    case "0":
                        color = "error";
                        value = "Failed"
                        break;
                    case "approval_requested":
                        break;
                    case "request_tokens_from_owner":
                        value = "success request AuthE"
                        color = "success";
                        break;
                    case "enable_auth_proxy":
                        value = "success AuthE Enabled"
                        color = "success";
                        break;
                    case "not_authe_provider":
                        color = "warning";
                        break;
                    case "transfer_with_auth_provider_disabled":
                        color = "warning";
                        value = "AuthE Provider not yet enabled";
                        break;
                    case "failed":
                        color = "error";
                        value = "failed - no approval requested"
                        break;
                    default:
                        break;
                }
                return <TagField value={value} color={color} />;
            }
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            title: 'Block Number',
            key: 'block_number',
            dataIndex: 'block_number',
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            dataIndex: "address",
            title: 'Import Originated from address',
            key: "address",
            render: (value: any) => {
                // let value: ComponentProps<typeof TagField>["value"];
                switch (value) {
                    case process.env.REACT_APP_PROXY_CONTRACT_ADDRESS?.toLowerCase():
                        return "AuthE Proxy"
                    case process.env.REACT_APP_CUSTOM_ERC20_CONTRACT_ADDRESS?.toLowerCase():
                        return "Custom ERC20 contract";
                    default:
                        return <TagField value={value} />;
                }
            }
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            dataIndex: "address_label",
            title: 'Import Originated from address label',
            key: "address_label",
            render: (value: any, record: any) => {
                // if (isEditing(record["user_id-block_timestamp-chain_id-hash-type"])) {
                    
                //     if (isEditingToAddressLabel == false) {
                //         setIsEditingToAddressLabel(true)
                //         if(value){
                //             setToAddressLabels(value)
                //         }
                //         else{
                            
                //             setToAddressLabels([])
                //         }
                //     }
                        
                //     return (
                //         <Form.Item
                //             name="address_label"
                //             style={{ margin: 0, width: 200, display: "flex" }}

                //         >
                //             <div className="container">
                //                 {
                //                     toAddressLabels.map((to_address_label: any) => <TagField id={to_address_label.name} key={to_address_label.name} value={to_address_label.name} color={"#" + to_address_label.color}  onClick={(e: any) => { removeToAddressLabel(to_address_label) }} style={{ marginTop: 5, marginBottom: 5 }}></TagField>)
                //                 }
                //                 <Input id="address_label" onKeyDown={(e: any) => { inputKeyDownToAddressLabels(e); }} placeholder='Add a new address label here' />

                //                 {/* <Input id="to_address_label" onChange={(e) => { onChangeToAddressLabelValue(e) }} value={newToAddressLabelValue} onKeyDown={(e: any) => { inputKeyDownToAddressLabels(e); }} placeholder='Add a new to address label here' /> */}
                //             </div>
                //         </Form.Item>
                //     );
                // }


                const local_address_labels = [];
                if (value) {
                    for (var address_label of record["address_label"]) {
                        local_address_labels.push(<TagField id={address_label.name} key={address_label.name} value={address_label.name} color={"#" + address_label.color} style={{ marginTop: 10 }} />)
                    }
                }
                return local_address_labels;
            }
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            title: 'Include Id',
            key: 'include_id',
            dataIndex: 'include_id',
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            dataIndex: "decoded_call",
            title: "Function Call",
            key: "decoded_call",
            render: (value: any) => {
                if (value) {
                    value = value.label

                    return <TagField value={value} />;
                }
                else {
                    return ""
                }
            }
        },
        {
            randomId: "",
            editable: true,
            hidden: false,
            position: "1",
            dataIndex: "tags",
            title: "Tags",
            key: "tags",
            render: (value: any, record: any) => {
                if (isEditing(record["user_id-block_timestamp-chain_id-hash-type"])) {
                    if (isEditingTag == false) {
                        setIsEditingTag(true)
                        if(value){
                            setTags(value)
                        }
                        else{
                            setTags([])
                        }
                    }
                        
                    return (
                        <Form.Item
                            name="title"
                            style={{ margin: 0, width: 200, display: "flex" }}

                        >
                            <div className="container">
                                {
                                    tags.map((tag: any) => <TagField id={tag.name} key={tag.name} value={tag.name} color={"#" + tag.color}  onClick={(e: any) => { removeTag(tag) }} style={{ marginTop: 5, marginBottom: 5 }}></TagField>)
                                }
                                <Input id="tags" onKeyDown={(e: any) => { inputKeyDownTags(e); }} placeholder='Add a new tag here' />

                                {/* <Input id="tags" onChange={(e) => { onChangeTagValue(e) }} value={newTagValue} onKeyDown={(e: any) => { inputKeyDownTags(e); }} placeholder='Add a new tag here' /> */}
                            </div>
                        </Form.Item>
                    );
                }


                const local_tags = [];
                if (value) {

                    for (var tag of record["tags"]) {
                        local_tags.push(<TagField id={tag.name} key={tag.name} value={tag.name} color={"#" + tag.color} style={{ marginTop: 10 }} />)
                    }
                }
                return local_tags;
            }
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            dataIndex: "tracer_id",
            title: "Tracer_id",
            key: "tracer_id",
            render: (value: any) => {
                if (value) {
                    return <Typography.Link id="given_name" href="#">{value}</Typography.Link>
                } else {
                    return <Typography.Link id="given_name" href="#">Create New Tracer</Typography.Link>
                }
            }
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            title: "Edit",
            dataIndex: "edit",
            render: (_: any, record: any) => {
                if (isEditing(record["user_id-block_timestamp-chain_id-hash-type"])) {
                    return (
                        <Space>
                            <SaveButton
                                id={"save_button" + record["user_id-block_timestamp-chain_id-hash-type"]}
                                key={"save_button" + record["user_id-block_timestamp-chain_id-hash-type"]}
                                {...saveButtonProps}
                                // hideText
                                size="small"
                                onClick={(e) => {
                                    if(newTagValue != ""){
                                        if (tags.find(tag => tag.name === newTagValue.toLowerCase())) {
              
                                        }
                                        else{
                                            tags.push({"name": newTagValue, "color": ""})
                                        }
                                    }
                                    if(newGroupNameValue != ""){
                                        if (groupNames.find(group_name => group_name.name === newGroupNameValue.toLowerCase())) {
              
                                        }
                                        else{
                                            groupNames.push({"name": newGroupNameValue, "color": ""})
                                        }
                                    }                                    
                                    if(newFromAddressLabelValue != ""){
                                        if (fromAddressLabels.find(from_address_label => from_address_label.name === newFromAddressLabelValue.toLowerCase() || from_address_label?.id.startsWith("address_label"))) {
              
                                        }
                                        else{
                                            fromAddressLabels.push({"id": "", "name": newFromAddressLabelValue, "color": ""})
                                        }
                                    } 
                                    if(newToAddressLabelValue != ""){
                                        if (toAddressLabels.find(to_address_label => to_address_label.name === newToAddressLabelValue.toLowerCase() || to_address_label?.id.startsWith("address_label"))) {
              
                                        }
                                        else{
                                            toAddressLabels.push({"id": "", "name": newToAddressLabelValue, "color": ""})
                                        }
                                    } 

                                    var newTags = tags.map(tag => tag.name);

                                    var newGroupNames = groupNames.map(group_name => group_name.name);

                                    var newFromAddressLabels = fromAddressLabels.map(from_address_label => {
                                        if (!from_address_label.id.startsWith("address_label_")){
                                            return from_address_label.name
                                        }  // Only default labels start with 'label_'
                                        else{
                                            return null
                                        }
                                    });

                                    var newToAddressLabels = toAddressLabels.map(to_address_label => {
                                        if (!to_address_label.id.startsWith("address_label_")){
                                            return to_address_label.name
                                        }  // Only default labels start with 'label_'
                                        else{
                                            return null
                                        }
                                    });

                                    mutate({
                                        resource: "transactions",
                                        id: record["user_id-block_timestamp-chain_id-hash-type"],
                                        values: { 
                                            tags: newTags,
                                            group_names: newGroupNames,
                                            from_address_label: newFromAddressLabels,
                                            to_address_label: newToAddressLabels
                                        },
                                        mutationMode: "optimistic",
                                        onCancel: (cancelMutation) => {
                                            cancelRef.current = cancelMutation;
                                        },
                                    });

                                    setTags(prevState => tags);
                                    setGroupNames(prevState => groupNames);

                                    setEditId(prevState => "")
                                    setIsEditingTag(false)
                                    setIsEditingGroupName(false)
                                    setNewTagValue(prevState => "")
                                    setNewGroupNameValue(prevState => "")
                                    setNewFromAddressLabelValue(prevState => "")
                                    setNewToAddressLabelValue(prevState => "")
                                    console.log(tags)
                                }}
                            />
                            <Button 
                                id={"cancell_button" + record["user_id-block_timestamp-chain_id-hash-type"]}
                                key={"cancell_button" + record["user_id-block_timestamp-chain_id-hash-type"]}
                                {...cancelButtonProps}
                                size="small"
                                onClick={(e) => {
                                    setTags(prevState => record["tags"]);
                                    setGroupNames(prevState => record["group_names"]);
                                    setEditId(prevState => "")
                                    setIsEditingTag(false)
                                    setIsEditingGroupName(false)
                                    setNewTagValue(prevState => "")
                                    setNewGroupNameValue(prevState => "")
                                    setNewFromAddressLabelValue(prevState => "")
                                    setNewToAddressLabelValue(prevState => "")
                                    console.log(tags)
                                    cancelDelete()

                                    mutate({
                                        resource: "transactions",
                                        id: record["user_id-block_timestamp-chain_id-hash-type"],
                                        values: { 
                                            error: "cancelFromUpdateTransactions"
                                        },
                                        mutationMode: "pessimistic",
                                        onCancel: (cancelMutation) => {
                                            cancelRef.current = cancelMutation;
                                        },
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                        </Space>
                    );
                }
                return (
                    <EditButton
                        id={"edit_button" +record["user_id-block_timestamp-chain_id-hash-type"]}
                        key={"edit_button" + record["user_id-block_timestamp-chain_id-hash-type"]}
                        {...editButtonProps(record["user_id-block_timestamp-chain_id-hash-type"])}
                        hideText
                        size="small"
                        onClick={(e) => {
                            if(isEditing(record["user_id-block_timestamp-chain_id-hash-type"])){
                                console.log("Same row")
                            }
                            else{
                                console.log("Changed row")
                                setEditId && setEditId(record["user_id-block_timestamp-chain_id-hash-type"]);
                                if(typeof record["tags"] == "undefined"){
                                    setTags([])
                                } else{
                                    setTags(record["tags"])
                                }

                                if(typeof record["group_names"] == "undefined"){
                                    setGroupNames([])
                                } else{
                                    setGroupNames(record["group_names"])
                                }

                                if(typeof record["from_address_label"] == "undefined"){
                                    setFromAddressLabels([])
                                } else{
                                    setFromAddressLabels(record["from_address_label"]?.filter((label: { id: any; }) => !label.id.startsWith("address_label_"))) // Only default labels start with 'label_'
                                }

                                if(typeof record["to_address_label"] == "undefined"){
                                    setToAddressLabels([])
                                } else{
                                    setToAddressLabels(record["to_address_label"]?.filter((label: { id: any; }) => !label.id.startsWith("address_label_")))  // Only default labels start with 'label_'
                                }

                                setNewTagValue(prevState => "")
                                setNewGroupNameValue(prevState => "")
                                setNewFromAddressLabelValue(prevState => "")
                                setNewToAddressLabelValue(prevState => "")
                            }
                        }}
                    />
                );
            }
        },
        {
            randomId: "",
            editable: false,
            hidden: false,
            position: "1",
            title: "Show more",
            dataIndex: "show",
            render: (_: any, record: any) => (
                <Space>
                    <ShowButton
                        hideText
                        size="small"
                        recordItemId={record["user_id-block_timestamp-chain_id-hash-type"]}
                    />
                </Space>
            )
        },
        {
            randomId: "Owner",
            editable: false,
            hidden: true,
            position: "1",
            key: "owner",
            title: "Owner",
            dataIndex: "owner",
            render: (_: any, record: any) => (
                <TagField
                    key={record["owner"]}
                    value={`${record["owner"]
                        }`}
                />
            ),
        },
        {
            randomId: "",
            editable: false,
            hidden: true,
            position: "1",
            key: "user_id-block_timestamp-chain_id-hash-type",
            title: "id",
            dataIndex: "user_id-block_timestamp-chain_id-hash-type",
            render: (_: any, record: any) => (
                <TagField
                    key={record["user_id-block_timestamp-chain_id-hash-type"]}
                    value={`${record["user_id-block_timestamp-chain_id-hash-type"].substring(0, 10)
                        }`}
                />
            ),
        }
    ]

    function onOrigionalColumns() {
        let displayColumns = localStorage.getItem('transactionDisplayColumns') ? JSON.parse(localStorage['transactionDisplayColumns']) : columns
        
        for (var column of columns) {
            let columnIsAlsoInDisplayColumns = false
            for (var displayColumn of displayColumns) {
                if (column.title == displayColumn.title) {
                    column.hidden = displayColumn.hidden
                    columnIsAlsoInDisplayColumns = true
                    break
                }
            }
            if (columnIsAlsoInDisplayColumns == false){
                column.hidden = true
            }
        }
        
        setOrigionalColumns(columns.filter((item: { hidden: any; }) => !item.hidden));


    }

    function toggleColumn(e: any, columns: any) {
        console.log("Toggling column")
        const title = e.target.id
        for (var column of columns) {
            if (column.title == title) {
                column.hidden = !e.target.checked
            }
        }
        setOrigionalColumns(columns.filter((item: { hidden: any; }) => !item.hidden));

        localStorage.setItem("transactionDisplayColumns", JSON.stringify(columns.filter((item: { hidden: any; }) => !item.hidden)));
    }

    const [origionalColumns, setOrigionalColumns] = useState<Array<{
        randomId: string,
        hidden: boolean,
        position: string,
        title: string,
        dataIndex: string,
        render: any
    }>
    >([])

    const [state, setState] = useState({ name: "" });

    const onValueChange = (e: any) => {
        setState({ name: e });
    };

    useEffect(() => {
        console.log("effect")

        changeOrigionalColumnTitles(columns)
        onOrigionalColumns()
        
        console.log(pageSize)
        if(limit == 0){
            setLimit(pageSize)
        }

    }, [state, groupNames, tags, newGroupNameValue, newTagValue, newFromAddressLabelValue, newToAddressLabelValue, fromAddressLabels, toAddressLabels, lastEvaluatedKey, limit, pageSize, label, value]);

    return (
        <List
            headerButtons={() => {
                return (
                    <Space 
                        style={{
                            marginTop: "1.6rem",
                        }}
                    >
                        {/* <Radio.Button value="table">
                        <SettingOutlined />
                    </Radio.Button> */}

                        <Form {...searchFormProps} layout="inline">
                            <Form.Item 
                                name="name"  
                                style={{ width: 500 }}
                                rules={[
                                    {
                                        required: false,
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                          if (value) {
                                            if (value.length > 1) {
                                              callback("No more than 1 filter. Use advanced search for more complex search");
                                            } else{
                                              callback();
                                            }
                                          }

                                          return;
                                        }
                                      }
                                ]}
                            >
                                <Spin spinning={searchLoading}></Spin>
                                <DebounceSelect
                                    size="large"
                                    mode="multiple"
                                    suffixIcon={
                                        <SearchOutlined 
                                            className="anticon tertiary" 
                                            onClick={(e:any) => {
                                                setValue(value);
                                                setLabel(label)
                                                setCurrent(1)
                                                setLastEvaluatedKey("null")
                                                setHistoricEvaluatedKey([])
                                                tableQueryResult.refetch()
                                                
                                            }}
                                            onPointerEnterCapture={true} onPointerLeaveCapture={true}
                                        />
                                    }
                                    value={value}
                                    allowClear
                                    placeholder="Filter by tags or attributes or search by keyword..."                                    
                                    fetchOptions={fetchSearchList}
                                    onChange={(newValue:any) => {
                                        if(newValue.length == 1){
                                            setCurrent(1)
                                            setLastEvaluatedKey("null")
                                            setHistoricEvaluatedKey([])
                                            searchWithFilter(newValue)
                                        }
                                        if(newValue.length == 0){
                                            setCurrent(1)
                                            setLastEvaluatedKey("null")
                                            setHistoricEvaluatedKey([])
                                            tableQueryResult.refetch()
                                            setTransactionsResult(tableQueryResult?.data ? tableQueryResult.data.data : [])
                                        }
                                        setValue(newValue as SearchValue);
                                        setLabel(newValue.length > 0 ? newValue[0].label: "null")
                                    }}
                                    onKeyUp={(e:any) => {
                                        if (e.key === "Enter") {
                                            setValue(value)
                                            setLabel(label)
                                            setCurrent(1)
                                            setLastEvaluatedKey("null")
                                            setHistoricEvaluatedKey([])
                                            tableQueryResult.refetch()
                                        }
                                    }}
                                    style={{ width: '100%' }}
                                    />
                            </Form.Item>
                        </Form>
                        <Link style={{marginRight: 10 }} to={''}>advanced search</Link>
                        <Button onClick={() => createColumnsModalShow()} type="primary">
                            Display
                        </Button>
                        <Button onClick={() => importTransactionModalShow()} type="primary">
                            Import Transaction
                        </Button>
                        <Modal {...createColumnsModalProps} title="Select columns to show">
                            <Form key="checkboxForm" {...createColumnsFormProps} layout="vertical" onFinish={onFinishHandler}>
                                {
                                    origionalColumnTitles.map(column => {
                                        return <Form key={column.title}>
                                            <Form.Item key={column.title} id={column.title} name={column.title}>
                                                <Checkbox key={column.title} id={column.title} onChange={(e) => { toggleColumn(e, origionalColumnTitles) }} defaultChecked={!column.hidden}>{column.title} </Checkbox>
                                            </Form.Item>
                                        </Form>
                                    })
                                }
                            </Form>
                        </Modal>
                        <Modal {...importTransactionModalProps} title="Transaction to import" centered>
                            <Form key="checkboxForm" {...importTransactionFormProps} layout="vertical"> 
                            <Form.Item
                                label="Hash"
                                name="hash"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder='0x57b1ab0ce2084d87f6f03a7911871ecbf179020564e3dff486ea48f4da27f6f3'/>
                            </Form.Item>
                            <Form.Item
                                label="Chain"
                                name="chain"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                            <Select
                                style={{ width: 500 }}
                                allowClear
                                placeholder="Please select"
                                // onChange={handleSelectedRoleChange}
                                options={[
                                    {"label": "Ethereum", "value": "0x1"}, 
                                    {"label": "Sepolia", "value": "0xaa36a7"}, 
                                    {"label": "Avalanche C-Chain", "value": "0xa86a"}, 
                                    {"label": "Polygon Mainnet", "value": "0x89"},
                                    {"label": "Polygon Mumbai", "value": "0x13881"},
                                    {"label": "Bsc", "value": "0x38"},
                                    {"label": "Bsc Testnet", "value": "0x61"},
                                    {"label": "Fantom", "value": "0xfa"},
                                    {"label": "Cronos Mainnet", "value": "0x19"},
                                    {"label": "Palm", "value": "0x2a15c308d"},
                                    {"label": "Arbitrum", "value": "0xa4b1"},
                                    {"label": "Gnosis", "value": "0x64"},
                                    {"label": "Gnosis Chiado", "value": "0x27d8"},
                                    {"label": "Base", "value": "0x2105"},
                                    {"label": "Base Goerli", "value": "0x14a33"},
                                    {"label": "Optimism", "value": "0xa"},
                                ]}
                            />
                            </Form.Item>
                            </Form>
                        </Modal>
                    </Space>

                )
            }}
        >
            <Form {...formProps}>
                <Table
                    {...tableProps}
                    dataSource={tableQueryResult?.data ? tableQueryResult.data.data : []}
                    rowKey="user_id-block_timestamp-chain_id-hash-type"
                    pagination={{
                        defaultPageSize:pageSize,
                        showSizeChanger:true,
                        onShowSizeChange:(newCurrent, newPageSize) => {
                            console.log("newCurrent " + newCurrent)
                            console.log("newPageSize " + newPageSize)
                            console.log("pageSize " + pageSize)
                            setLimit(newPageSize)
                            setPageSize(newPageSize)
                            setCurrent(1)
                            setLastEvaluatedKey("null")
                            setHistoricEvaluatedKey([])
                        },
                        total: tableQueryResult.data?.last_evaluated_key ? current * Math.max.apply(Math, [limit, 10]) + 1: current * pageSize,
                        itemRender: (page: any, type: any) => {
                            // setPageSize(limit)
                            // setLimit(pageSize)
                            if(current != 1 && historicEvaluatedKey.length == 0 ){
                                setCurrent(1)
                            }
                            
                            if(tableQueryResult.data?.last_evaluated_key && !historicEvaluatedKey[current - 1 ] && historicEvaluatedKey.indexOf(tableQueryResult.data?.last_evaluated_key) == -1 ){

                                // console.log("here " + lastEvaluatedKey)
                                let newHistoricEvaluatedKey = historicEvaluatedKey
                                newHistoricEvaluatedKey[current - 1] = tableQueryResult.data?.last_evaluated_key
                                setHistoricEvaluatedKey(newHistoricEvaluatedKey);
                                // setLastEvaluatedKey(tableQueryResult.data?.last_evaluated_key)
                            }

                            // console.log(tableQueryResult?["last_evaluated_key"][0]: "")
                            // console.log(tableQueryResult.data?.last_evaluated_key)

                            if(type =="next"){
                                return <a onClick={handleLastEvaluatedKey}>Next &gt;</a>
                            } 
                            else if(type =="prev"){
                                return <a onClick={handlePrevEvaluatedKey}>&lt; Prev</a>
                            }
                            else if(page == current){
                                return <a>{page}</a>
                            }
                            else{
                                return
                            }

                        }
                    }}
                    columns={origionalColumns}
                    onRow={(record) => ({
                        // eslint-disable-next-line
                        onClick: (event: any) => {
                            onValueChange("NeededToTriggerEffect")

                            // if (event.target.nodeName === "TD") {
                            // if(isEditing(record["user_id-block_timestamp-chain_id-hash-type"])){
                            //     console.log("Same row")
                            // }
                            // else{
                            //     console.log("Changed row")
                            //     setEditId && setEditId(record["user_id-block_timestamp-chain_id-hash-type"]);
                            //     if(typeof record["tags"] == "undefined"){
                            //         setTags([])
                            //     } else{
                            //         setTags(record["tags"])
                            //     }

                            //     if(typeof record["group_names"] == "undefined"){
                            //         setGroupNames([])
                            //     } else{
                            //         setGroupNames(record["group_names"])
                            //     }

                            //     setNewTagValue(prevState => "")
                            //     setNewGroupNameValue(prevState => "")
                            // }
                            // }
                        },
                    })}
                >
                </Table>
            </Form>
        </List>
    );
};
