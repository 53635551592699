import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi'

import { useShow, IResourceComponentsProps, useOne, useNotification } from "@refinedev/core";

import { TagField, Show, DeleteButton, SaveButton, ShowButton, useModalForm, EditButton } from "@refinedev/antd";

import { Avatar, Button, Card, Checkbox, Col, Divider, Form, Input, List, Modal, Row, Select, SelectProps, Skeleton, Space, Spin, Statistic, Switch, Tag, Tooltip, Typography } from "antd";

import { ITransaction } from "interfaces";
import { EditOutlined, DeleteOutlined, SettingOutlined, ExpandAltOutlined, CloseOutlined, CheckOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import Meta from "antd/es/card/Meta";
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import ForceGraph2D from "react-force-graph-2d";

const { Title, Text } = Typography;

import { useModal } from "@refinedev/antd";
import debounce from 'lodash/debounce';
import { useAuth0 } from "@auth0/auth0-react";

interface DataType {
    gender: string;
    name: {
      title: string;
      first: string;
      last: string;
    };
    email: string;
    picture: {
      large: string;
      medium: string;
      thumbnail: string;
    };
    nat: string;
  }

export interface DebounceSelectPropsTransactionShow<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

export const TransactionShow: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const { address } = useAccount()
    const { open } = useNotification();
    const cdn_domain_name = process.env.REACT_APP_CDN_URL
    
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const local_storage_prefix = "DASHBOARD-" + process.env.REACT_APP_ENV + "-" + user?.[process.env.REACT_APP_BASE_URL + '/org_id'] + "-" + user?.[process.env.REACT_APP_BASE_URL + '/tenant_id'] + "-" + user?.[process.env.REACT_APP_BASE_URL + '/sub']

    const token = JSON.parse(localStorage.getItem('token') || '{}');
    let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')
  
    const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"]
    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
    const region = region_config?.region
    const region_alias = region_config?.region_alias
    const backup_region = region_config?.backup_region
    const backup_region_alias = region_config?.backup_region_alias
    const domain = process.env.REACT_APP_API_BASE_URL 
  
    let use_region:string
    let use_region_alias
    let use_backup_region
    let use_backup_region_alias
  
    if(storage_use_region_alias && storage_use_region_alias != "default" && storage_use_region_alias != undefined){
        if(storage_use_region_alias == region_alias){
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias                   
        } else{
            // Switch
            use_backup_region = region
            use_backup_region_alias = storage_use_region_alias
            use_region = backup_region
            use_region_alias = backup_region_alias
        }
    } else{
        // Switch region based on time/minutes. To semi-randomly switch to backup region
        const now = new Date().getUTCMinutes() % 5 // get remainder of 5 minutes interval and check if this is greater than something. First 3 minutes 1 region. Remainder 2 minutes other region
        if(now > 2){ // 3 and 4
            use_backup_region = region_alias 
            use_backup_region_alias = region_alias 
            use_region = backup_region
            use_region_alias = backup_region_alias
            console.log("Using back up region: " + use_region_alias) 
        }
        else{ // 0,1,2
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias
            console.log("Using main region: " + use_region_alias) 
        }
    }
  
    const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
    const qa_environment = qa_config["environment"]
    const blue_config = qa_config["config"]

    const { queryResult } = useShow<ITransaction>();
    const { data, isLoading, isFetching, isRefetching } = queryResult;
    const record = data?.data;

    const [recoveredAddress, setRecoveredAddress] = useState('');

    // const { data: categoryData, isLoading: categoryIsLoading } =
    //     useOne<ITransaction>({
    //         resource: "transactions",
    //         id: record?.hash ? record?.hash : "",
    //         queryOptions: {
    //             enabled: !!record,
    //         },
    //         meta: {"transaction_hash": record?.hash}
    //     });

    const [disabled, setDisabled] = useState(false);

    const { show, close, modalProps } = useModal();
    const { show: setShowInternalTransaction, close: closeInternalTransaction, modalProps:internalTransactionModalProps } = useModal();
    const { show: setShowTransactionDecodedInput, close: closeTransactionDecodedInput, modalProps:transactionDecodedInputModalProps } = useModal();
    const { show: setShowEvent, close: closeEvent, modalProps:eventModalProps } = useModal();

    const [showIntegrationIdDetails, setShowIntegrationIdDetails] = useState("");
    const [showInternalTransactionDetails, setShowInternalTransactionDetails] = useState("");
    const [showEventDetails, setShowEventDetails] = useState("");

    const showIntegrationDetails = async (record: any) => {
        console.log("Show integration details")
        console.log(record)
        show()
        setShowIntegrationIdDetails(record);
      };

    const showInternalTransaction = async (record: any) => {
        console.log("Show internal transaction")
        console.log(record)
        setShowInternalTransaction()
        setShowInternalTransactionDetails(record);
    };

    const showEvent = async (record: any) => {
        console.log("Show event")
        console.log(record)
        setShowEvent()
        setShowEventDetails(record);
      };

    function DebounceSelect<
      ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
      >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectPropsTransactionShow<ValueType>) {
      const [fetching, setFetching] = useState(false);
      const [options, setOptions] = useState<ValueType[]>([]);
      const fetchRef = useRef(0);

        const debounceFetcher = useMemo(() => {
            const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
            };

            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
            <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
            />
        );
    }

    // Usage of DebounceSelect
    interface UserValue {
        label: string;
        value: string;
    }

    const [isLoadingReimportTransaction, setIsLoadingReimportTransaction] = React.useState<boolean>(false);

    async function reimportTransaction() {
        setIsLoadingReimportTransaction(true)
        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": use_region,
            "Destination-Region": use_region,
        };

        const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")


        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/private/blue/ANY/${continent.toUpperCase()}/v0/transactions/import-transaction`
        }  
        else{
            url = `${apiUrl}/private/ANY/${continent.toUpperCase()}/v0/transactions/import-transaction`
        }

        console.log(url)
        const hash = record?.hash
        const chain_id = record?.chain_id
        const force_update = true

        const postData = {
            "hash": hash,
            "chain": chain_id,
            "force_update": force_update
        }
        
        try {
            const { data, status } = await axios.post(
                url,
                JSON.stringify(postData), {
                    headers: requestHeaders
                }
            )  
            console.log(data)
            console.log(status)
            setIsLoadingReimportTransaction(false)

            open?.({
                type: "success",
                message: "Successfully requested the reprocess of the transaction",
                description: "Successfully requested the reprocess of the transaction",
                key: "hash",
            })
        } catch (error: any) {
            console.log(error); 
            setIsLoadingReimportTransaction(false)

            open?.({
                type: "error",
                message: "Error",
                description: error.response?.data?.error?.message,
                key: "transactions-import",
            });
        } 

        console.log(data)

        return {
            data,
        };
    }


    const shareTransaction = async () => {
        console.log("Share transaction")
        showShareWithModal()
      };
      
    async function fetchUserList(username: string) {
        console.log('fetching user', username);
     
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": "us-west-2",
            "Destination-Region": "us-west-2",
        };

        const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/management/permissions/blue/GET/${continent.toUpperCase()}/v0/users`
        }  
        else{
            url = `${apiUrl}/management/permissions/GET/${continent.toUpperCase()}/v0/users`
        }
        console.log(url)

        return fetch(url, {headers: requestHeaders})
          .then((response) => 
            response.json() 
          )
          .then((body) =>
            body.result?.map(
              (user: { name: string; email: string }) => ({
                label: `${user.name} - ${user.email}`,
                value: user.email,
              }),
            ),
          );
      }
    
    const [value, setValue] = useState<UserValue[]>([]);
    const {
        modalProps: modalPropsShareWithModal,
        formProps: formPropsShareWithModal,
        show: showShareWithModal,
        close: closeShareWithModal,
        onFinish
    } = useModalForm({
        action: "create",
        resource: "transactions-share-with",
        warnWhenUnsavedChanges: false,
        syncWithLocation: false,
        disableServerSideValidation: true,
        onMutationSuccess: (data, variables, context, isAutoSave) => {
            closeShareWithModal()
        },
    });

    const [loading, setLoading] = useState(false);
    const loadMoreData = () => {

    }

    const onFinishShareWith = (values : any ) => {
        console.log("hello finish")
        console.log(values)
        console.log("hello action")
       
        let shared_with = values.shared_with
        let shared_with_to_add_array = []
        for(let i=0; i<shared_with.length; i++){
            console.log(shared_with[i].value);
            shared_with_to_add_array.push(shared_with[i].value)
        }

        let new_obj = {}
       
        new_obj = {
            id: `${record?.["user_id-block_timestamp-chain_id-hash-type"]}`,
            shared_with_to_add: shared_with_to_add_array,
            shared_with_to_remove: []
        }


        console.log("hello obj")
        console.log(new_obj)
        onFinish?.(new_obj);

        close();
    };

    const [images, setImages] = useState<Array<HTMLImageElement>>();

    function componentDidMount() {
        const nodeImages = record?.nodes?.map(node => node.source);
        const nodeImagesUnique = Array.from(new Set(nodeImages?.map((item: any) => item)))

        const images = nodeImagesUnique?.map(image => {
        const img = new Image();
        img.src = image;
        return img;
        });
        setImages(images)
        console.log(images)
    }

    const fgRef = useRef<any>();

    const graphDataNew = useMemo(() => {
        const nodeImages = record?.nodes?.map(node => node.source);
        const nodeImagesUnique = Array.from(new Set(nodeImages?.map((item: any) => item)))
    
        const images = nodeImagesUnique?.map(image => {
          const img = new Image();
          img.src = image;
          return img;
        });
        setImages(images)

        return {
          nodes: record?.nodes ? record?.nodes : [],
          links: record?.links ? record?.links : []
        };
    }, [record]);

    useEffect(() => {
        if(!images){
            componentDidMount()
        }

        const current = fgRef?.current
        current?.zoom(5)
    }, [images, record]);
    
    return (
        <Show isLoading={isLoading} footerButtons={({deleteButtonProps }) => (
            <>
                <ShowButton onClick={(e) => shareTransaction()}>Share transaction with other users</ShowButton>
                <Button type="primary" loading={isLoadingReimportTransaction} onClick={(e) => reimportTransaction()}>Reimport the transaction</Button>
            </>
            
        )}
        >
            <Title level={5}>Hash</Title>
            <Text>{record?.hash}</Text>

            {/* <Title level={5}>Address</Title>
            <Text>{record?.address}</Text>    */}

            <Title level={5}>From Address</Title>
            <Text>{record?.from_address}
            {         
                        record?.from_address_label?.map((label: any)=>{
                        return <Tag style={{marginLeft:"10px"}} color={"#" + label.color}>{label.name}</Tag>
                    })
            }  
             </Text>   
            <Title level={5}>To Address</Title>
            <Text>{record?.to_address + " "}  
            {         
                record?.to_address_label?.map((label: any)=>{
                return <Tag style={{marginLeft:"10px"}} color={"#" + label.color}>{label.name}</Tag>
            })
            }   
            </Text>   
            <Title level={5}>Status</Title>
            <Text>
                <Tag color={record?.receipt_status == "0" ? "red" : "green"}>{record?.receipt_status == "0" ? "Failed" : "Confirmed"}</Tag>   
            </Text>
            <Title level={5}>Decoded input of the transaction</Title>
            <Text>

                <Tooltip title="from">
                <Button onClick={(e) => setShowTransactionDecodedInput()}
                    size="small"
                >{"Show decoded input"}</Button>
                </Tooltip>
            </Text>            
            <Title level={5}>Chain</Title>
            
            <Avatar size="large" src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`} /> 
            <Text>{" " +  record?.chain[0].toUpperCase() +  record?.chain.slice(1)}</Text>  

            <Text> 
            <Row gutter={16}>
            {
            record?.included_by ? record?.included_by?.map((record: any, index: any) => 
                <><Col span={12}>
                    { index == 0 ? <Title level={5}>Included by items</Title> : "" }
                    <Text> 
                        <Tooltip title={record}><Link to={'https://' + process.env.REACT_APP_API_BASE_URL + "/transactions/show/"+ record} target="_blank">Link to main item</Link></Tooltip>
                    </Text>
                </Col>
                </>
            ) : ""}
            </Row>
            </Text>
            
            <Title level={5}>Tags</Title>
            <Text>
                {         
                        record?.tags?.map((tag: any)=>{
                        return <Tag color={"#" + tag.color}>{tag.name}</Tag>
                    })
                }   
            </Text>
            
            <Title level={5}>Group(s)</Title>
            {         
                    record?.group_names?.map((group: any)=>{
                    return <Tag color={"#" + group.color}>{group.name}</Tag>
                })
            }   
            <Row gutter={16}>
            {
            record ? record?.group_assignments_result?.map((record: any, index: any) => 
                <><Col span={12}>
                <Card
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    bordered={false}
                    actions={[
                    <Tooltip title="More"><Link to={'https://' + process.env.REACT_APP_API_BASE_URL + "/transactions?pageSize=10&current=1&group_name="+ record.group_name} target="_blank"><ExpandAltOutlined key="more" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Link></Tooltip>,
                    ]}
                >
                        <Tooltip title={record.group_name}>
                            <Statistic title={"Transactions in group: " + record?.group_name?.substring(0, 10)} value={record.count} />
                        </Tooltip>
                        {/* <Meta
                            description={         
                                record?.assignments?.map((group: any)=>{
                                return "-teds " +  group
                            })
                        } 
                        /> */}
                        <Title level={5}>Transaction(s)</Title>
                        {/* {         
                                record?.assignments?.map((assignment: any)=>{
                                return <Typography.Link id="assignment" href="#">{assignment}</Typography.Link>
                            })
                        }    */}
                        <List
                            itemLayout="horizontal"
                            dataSource={record?.assignments}
                            renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record.assignments[0].chain_id}.svg`} />}

                                    // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                                    title={<Link to={'https://' + process.env.REACT_APP_API_BASE_URL + "/transactions?pageSize=10&current=1&group_name="+ item} target="_blank">{record?.assignments[index]?.transaction_id?.split("-").at(-2)}</Link>}
                                    // description={record?.assignments[index]}
                                />
                            </List.Item>
                            )}
                        />
                    </Card>
                </Col>
                </>
            ) : ""}
            </Row>

            <Title level={5}>Processed by the following Integrations:</Title>
            <>
                <Modal {...modalProps} width="1000" centered title={"Integration Info " + showIntegrationIdDetails } onOk={close}>
                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <Text>Integration Name: {record.integration_name}</Text>
                    )} 
                    </p>

                    <Title level={3}>Globals:</Title>
                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <> 
                        <Title level={5}> Integration global tags:</Title>
                        <Text>
                        {         
                                record?.global_tags?.map((tag: any)=>{
                                return <Tag>{tag}</Tag>
                            })
                        }   
                        </Text>
                        </>
                    )} 
                    </p>

                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <> 
                        <Title level={5}> Integration global from address labels:</Title>
                        <Text>
                        {         
                                record?.global_from_address_labels?.map((label: any)=>{
                                return <Tag>{label}</Tag>
                            })
                        }   
                        </Text>
                        </>
                    )} 
                    </p>

                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <> 
                        <Title level={5}> Integration global to address labels:</Title>
                        <Text>
                        {         
                                record?.global_to_address_labels?.map((label: any)=>{
                                return <Tag>{label}</Tag>
                            })
                        }   
                        </Text>
                        </>
                    )} 
                    </p>

                    <Title level={3}>Main:</Title>
                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <> 
                        <Title level={5}> Integration main tags:</Title>
                        <Text>
                        {         
                                record?.main_tags?.map((tag: any)=>{
                                return <Tag>{tag}</Tag>
                            })
                        }   
                        </Text>
                        </>
                    )} 
                    </p>

                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <> 
                        <Title level={5}> Integration main from address labels:</Title>
                        <Text>
                        {         
                                record?.main_from_address_labels?.map((label: any)=>{
                                return <Tag>{label}</Tag>
                            })
                        }   
                        </Text>
                        </>
                    )} 
                    </p>

                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <> 
                        <Title level={5}> Integration main to address labels:</Title>
                        <Text>
                        {         
                                record?.main_to_address_labels?.map((label: any)=>{
                                return <Tag>{label}</Tag>
                            })
                        }   
                        </Text>
                        </>
                    )} 
                    </p>

                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <> 
                        <Title level={5}> Integration main groups:</Title>
                        <Text>
                        {         
                                record?.main_groups?.map((group: any)=>{
                                return <Tag>{group}</Tag>
                            })
                        }   
                        </Text>
                        </>
                    )} 
                    </p>

                    <Title level={3}>Attached:</Title>
                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <> 
                        <Title level={5}> Integration attached tags:</Title>
                        <Text>
                        {         
                                record?.tags?.map((tag: any)=>{
                                return <Tag>{tag}</Tag>
                            })
                        }   
                        </Text>
                        </>
                    )} 
                    </p>

                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <> 
                        <Title level={5}> Integration attached from address labels:</Title>
                        <Text>
                        {         
                                record?.from_address_labels?.map((label: any)=>{
                                return <Tag>{label}</Tag>
                            })
                        }   
                        </Text>
                        </>
                    )} 
                    </p>

                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <> 
                        <Title level={5}> Integration attached to address labels:</Title>
                        <Text>
                        {         
                                record?.to_address_labels?.map((label: any)=>{
                                return <Tag>{label}</Tag>
                            })
                        }   
                        </Text>
                        </>
                    )} 
                    </p>


                    <p>{record?.integration_results?.filter(integration => integration.integration_id == showIntegrationIdDetails)?.map((record: any, index) => 
                        <> 
                        <Title level={5}> Integration attached groups:</Title>
                        <Text>
                        {         
                                record?.groups?.map((group: any)=>{
                                return <Tag>{group}</Tag>
                            })
                        }   
                        </Text>
                        </>
                    )} 
                    </p>
                </Modal>
            </>

            <Row gutter={16}>
        {
            record ? record?.integration_results?.map((record: any, index) => 
            <Col span={8}>
                <Card
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    actions={[
                    <Tooltip title="More"><ExpandAltOutlined key="setting" onClick={(e) => {showIntegrationDetails(record.integration_id)}} onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                    <Tooltip title="Edit"><Link to={'https://' + process.env.REACT_APP_API_BASE_URL + "/integrations/show/"+ record.integration_id} target="_blank"><EditOutlined key="edit" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Link></Tooltip>,
                    ]}
                >
                    <Skeleton loading={false} avatar active>
                    <Meta
                        avatar={<Avatar src={"https://xsgames.co/randomusers/avatar.php?g=pixel&key=" + index} />}
                        title={record.integration_id}
                        description={record.integration_name}
                    />
                    {/* <div className="additional" style={{marginTop: 20}}>
                        <p>Type: <span>{record.type}</span></p>
                        <p>Stage: <span>{record.stage}</span></p>
                    </div> */}
                    {/* <Spin spinning={isLoading ? isLoading: false}>
                        <Switch checkedChildren={<CheckOutlined onPointerEnterCapture={true} onPointerLeaveCapture={true}/>} unCheckedChildren={<CloseOutlined onPointerEnterCapture={true} onPointerLeaveCapture={true}/>} style={{marginTop: 20}} defaultChecked={record.task.enabled} onChange={(e) => {onChangeMainScreenEnabledAction(e, index, record)}}  />
                    </Spin> */}
                    </Skeleton>
                    
                </Card>
            </Col>
            ) :             
            <Col span={8}>
                <Card
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    actions={[
                    <Tooltip title="More"><SettingOutlined key="setting_loading" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                    <Tooltip title="Edit"><EditOutlined key="edit_loading" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                    <Tooltip title="Delete"> <DeleteOutlined key="delete_loading" onPointerEnterCapture={true} onPointerLeaveCapture={true}/></Tooltip>,
                    ]}
                >
                    <Skeleton loading={true} avatar active>
                    </Skeleton>
                </Card>
            </Col>
        }
                    
        </Row>
        <Divider></Divider>
        {
            record?.shared_with ?
            <>
                <Title level={5}>Transaction shared with users:</Title>
                <InfiniteScroll
                dataLength={10}
                next={loadMoreData}
                hasMore={false}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                dataSource={record?.shared_with ? record?.shared_with : ["user1"] }
                renderItem={(item, index) => (
                    <List.Item key={item}>
                    <List.Item.Meta
                        avatar={<Avatar src={"https://xsgames.co/randomusers/avatar.php?g=pixel&key=" + index} />}
                        title={<a href="https://ant.design">{item.id}</a>}
                        description={item.email}
                    />
                    <Space>
                        {
                            record?.owner_id == item.id ?
                            <Button onClick={(e) => console.log()}
                                size="small"
                            >Owner</Button>
                          : ""
                        }  
                            <DeleteButton
                                size="small"
                                hideText={true}
                                recordItemId={record?.["user_id-block_timestamp-chain_id-hash-type"]}
                                resource="transactions-share-with"
                                meta={{"remove_user_email": item.email}}
                            />
                        </Space>
                    </List.Item>
                )}
                />
            </InfiniteScroll> 
        </>:
        ""
         }
        <>
            <Modal {...modalPropsShareWithModal} width="1000" centered title={"Share Transaction" }>
            <Form {...formPropsShareWithModal} onFinish={onFinishShareWith}>
                    <Form.Item label="Share the address with the following people in the organization"
                        name="shared_with"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                    <DebounceSelect
                        mode="multiple"
                        value={value}
                        placeholder="Select users"
                        fetchOptions={fetchUserList}
                        onChange={(newValue) => {
                            setValue(newValue as UserValue[]);
                        }}
                        style={{ width: 500 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>    
        <Divider></Divider>
        <Modal {...internalTransactionModalProps} width="1000" centered title={"Internal Transaction Info"} onOk={closeInternalTransaction}>
           <pre>{JSON.stringify(showInternalTransactionDetails, null, 2)}</pre>
        </Modal>       
        <Modal {...transactionDecodedInputModalProps} width="1000" centered title={"Decoded Transaction Info"} onOk={closeTransactionDecodedInput}>
           <pre>{record?.decoded_transaction?.decoded_call && JSON.stringify(record?.decoded_transaction?.decoded_call) != "{}" ? JSON.stringify(record?.decoded_transaction?.decoded_call, null, 2) : "Could not decode the transaction. Please try to add an ABI so next time we can decode it."}</pre>
        </Modal>     
                 
        {
            record?.internal_transactions ?
            <>
                <Title level={5}>Internal Transactions:</Title>
                <InfiniteScroll
                dataLength={10}
                next={loadMoreData}
                hasMore={false}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                dataSource={record?.internal_transactions ? record?.internal_transactions : [] }
                renderItem={(item, index) => (
                    <List.Item key={item}>
                    <List.Item.Meta
                        avatar={<Tooltip title={record?.address_items_map?.[item.to] ? record?.address_items_map?.[item.to]?.main_labels?.name ? record?.address_items_map?.[item.to]?.main_labels?.name : record?.address_items_map?.[item.to]?.labels?.[0]?.name  : `unknown address label`}><Avatar src={record?.address_items_map?.[item.to] ? record?.address_items_map?.[item.to]?.main_labels?.source ? record?.address_items_map?.[item.to]?.main_labels?.source : record?.address_items_map?.[item.to]?.labels?.[0]?.source  : `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`} /></Tooltip>}
                        title={<a href="https://ant.design">{item.type + " - " + (record?.decoded_transaction?.decoded_internal_transactions[index]?.input?.label ? record?.decoded_transaction?.decoded_internal_transactions[index]?.input?.label : record?.decoded_transaction?.decoded_internal_transactions[index]?.output?.label)}</a>}
                        description={<Tooltip title={item.transaction_hash}>{item.transaction_hash.substring(0,10)}</Tooltip>}
                    />
                    <Space>
                            <Tooltip title={record?.address_items_map?.[item.from] ? record?.address_items_map?.[item.from]?.main_labels?.source ? "From: " + record?.address_items_map?.[item.from]?.main_labels?.name : "From: " + record?.address_items_map?.[item.from]?.labels?.[0]?.name  : `unknown address label`}>
                            <Button onClick={(e) => showInternalTransaction(record?.decoded_transaction?.decoded_internal_transactions[index])}
                                size="small"
                            >{item.from}</Button>
                            </Tooltip>
                            <Text>{"->"}</Text>

                            <Tooltip title={record?.address_items_map?.[item.to] ? record?.address_items_map?.[item.to]?.main_labels?.source ? "To: " + record?.address_items_map?.[item.to]?.main_labels?.name : "To: " + record?.address_items_map?.[item.to]?.labels?.[0]?.name  : `unknown address label`}>
                            <Button onClick={(e) => showInternalTransaction(record?.decoded_transaction?.decoded_internal_transactions[index])}
                                size="small"
                            >{item.to}</Button>
                            </Tooltip>

                            <EditButton
                                size="small"
                                hideText={true}
                                recordItemId={record?.["user_id-block_timestamp-chain_id-hash-type"]}
                                resource="transactions-share-with"
                                meta={{"remove_user_email": item.email}}
                            />  
{/*                                                       
                            <DeleteButton
                                size="small"
                                hideText={true}
                                recordItemId={record?.["user_id-block_timestamp-chain_id-hash-type"]}
                                resource="transactions-share-with"
                                meta={{"remove_user_email": item.email}}
                            /> */}
                        </Space>
                    </List.Item>
                )}
                />
            </InfiniteScroll> 
        </>:
        ""
         }
        <Modal {...eventModalProps} width="1000" centered title={"Event Info"} onOk={closeEvent}>
           <pre>{JSON.stringify(showEventDetails, null, 2)}</pre>
        </Modal>           
        {
            record?.decoded_transaction?.decoded_events ?
            <>
                <Title level={5}>Events:</Title>
                <InfiniteScroll
                dataLength={10}
                next={loadMoreData}
                hasMore={false}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <List
                dataSource={record?.decoded_transaction?.decoded_events ? Object.values(record?.decoded_transaction?.decoded_events)  : [] }
                renderItem={(item:any, index) => (
                    <List.Item>
                    <List.Item.Meta
                        avatar={<Tooltip title={record?.address_items_map?.[item.address] ? record?.address_items_map?.[item.address]?.main_labels?.name ? record?.address_items_map?.[item.address]?.main_labels?.name : record?.address_items_map?.[item.address]?.labels?.[0]?.name  : `unknown address label`}><Avatar src={record?.address_items_map?.[item.address] ? record?.address_items_map?.[item.address]?.main_labels?.source ? record?.address_items_map?.[item.address]?.main_labels?.source : record?.address_items_map?.[item.address]?.labels?.[0]?.source  : `https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${record?.chain_id}.svg`} /></Tooltip>}
                        title={<a href="https://ant.design">{item.event ? item.event : item.label }</a>}
                        description={<Button onClick={(e) => showEvent(item)}size="small">{"Show decoded event info"}</Button>}
                    />
                    <Space>
                            <Tooltip title={record?.address_items_map?.[item.address] ? record?.address_items_map?.[item.address]?.main_labels?.name ? "Address: " +  record?.address_items_map?.[item.address]?.main_labels?.name : "Address: " +  record?.address_items_map?.[item.address]?.labels?.[0]?.name  : `unknown address label`}>
                            <Button onClick={(e) => showEvent(item)}
                                size="small"
                            >{item.address ? item.address : ""}</Button>
                            </Tooltip>
{/* 
                            <Tooltip title="to">
                            <Button onClick={(e) => showInternalTransaction(item)}
                                size="small"
                            >{item.to}</Button>
                            </Tooltip> */}

                            <EditButton
                                size="small"
                                hideText={true}
                                recordItemId={record?.["user_id-block_timestamp-chain_id-hash-type"]}
                                resource="transactions-share-with"
                                meta={{"remove_user_email": item.email}}
                            />     
                            {/*                        
                            <DeleteButton
                                size="small"
                                hideText={true}
                                recordItemId={record?.["user_id-block_timestamp-chain_id-hash-type"]}
                                resource="transactions-share-with"
                                meta={{"remove_user_email": item.email}}
                            /> */}
                        </Space>
                    </List.Item>
                )}
                />
            </InfiniteScroll> 
        </>:
        ""
         }         
         <Divider></Divider>
                { 
                    isLoading || isFetching || isRefetching ? 
                     <Spin spinning={isLoading || isFetching || isRefetching}> </Spin> 
                     
                     : 
                     <div>
                     <Divider></Divider>
                     <Title level={3}>Graph:</Title>
                     <ForceGraph2D
        
                        ref={fgRef}
                        graphData={graphDataNew}            
                        // graphData={gData}
                        nodeLabel={(node:any) => `<div><b>Name</b>: <span>${node.friendly_name}</span><br></br><b>Id</b>: <span>${node.id}</span><br></br><b>To/From address Labels</b>: <span>${node?.friendly_names?.map((friendly_name: { name: any; }) => friendly_name.name)}</span></div>`}
                        linkLabel={(link:any) => `<div><b>Name</b>: <span>${link.friendly_name}</span><br></br><b>Id</b>: <span>${link.id}</span><br></br><b>Tags</b>: <span>${link?.friendly_names?.map((friendly_name: { name: any; }) => friendly_name.name)}</span></div>`}
                        nodeAutoColorBy="group"
                        //   onNodeHover={(node:any, prevNode:any) => {
                        //     console.log(node)
                        //     if(node){
                        //         node.val = 20
                        //         node.friendly_name = node.friendly_names
                        //     }
                        //     return node
                        //   }}
                        backgroundColor="white"
                        onLinkHover={(link:any) => {
                            return <Tooltip title="x">x</Tooltip>
                        }}
            
                        linkDirectionalArrowColor={(link:any) => {
                            if (link.linkArrowColor != undefined){
                                return link.linkArrowColor
                            } else{
                                return "black"
                            }
                        }}
                        linkDirectionalArrowLength={(link:any) => {
                            return link.arraySize
                        }}
                        linkDirectionalArrowRelPos={(link:any) => {
                            return 0.3
                        }}
                        linkLineDash={(link:any) => {
                            return [1]
                        }}
                        linkCurvature={(link:any) => {
                            if (link.curved != undefined){
                                return link.curved
                            } else{
                                return 0
                            }
                        }}
                        linkColor={(link:any) => {
                            if (link.linkColor != undefined){
                                return link.linkColor
                            } else{
                                return link.linkColor
                            }
                        }}
                        onLinkClick={(link:any) => {
                            return window.open(
                                link.link_url,
                                '_blank' // <- This is what makes it open in a new window.
                            );
                        }}
                        //   linkLabel={(link:any) => {
                        //     console.log(link)
                        //     return "djknsdnjkfdkjn"
                        //   }}
                        nodeCanvasObjectMode={() => "replace"}
                        nodeCanvasObject={(node:any, ctx:any, globalScale:any) => {
                            // const label = node.name.substring(0, [6]);
                            const label = node.friendly_name.name
                            const fontSize = 12 / globalScale;
                            ctx.font = `${fontSize}px Sans-Serif`;
                            ctx.textAlign = "center";
                            ctx.textBaseline = "middle";
                            ctx.fillStyle = "black"; //node.color;
                            // if (node.isClusterNode) {
                            //   ctx.fillText(label, node.x!, node.y!);
                            // } else {
                            //   ctx.fillText(label, node.x! + 20, node.y!);
                            // }
                            const size = 12      
                            
                            if (images && node.source){
                                ctx.drawImage(images.find(img => img.src === node.source), node.x! - size / 2, node.y! - size / 2, size, size);
                            }
            
                        }}
                        
                        linkCanvasObjectMode={() => "after"}
                        linkCanvasObject={(link:any, ctx:any, globalScale:any) => {
                            const MAX_FONT_SIZE = 4;
                            const LABEL_NODE_MARGIN = 12;
                            const start = link.source;
                            const end = link.target;
                            // ignore unbound links
                            if (typeof start !== 'object' || typeof end !== 'object') return;
                            // calculate label positioning
                            const textPos = Object.assign({},...['x', 'y']?.map(c => ({
                            [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
                            })));
                            const relLink = { x: end.x - start.x, y: end.y - start.y };
                            const maxTextLength = Math.sqrt(Math.pow(relLink.x, 2) + Math.pow(relLink.y, 2)) - LABEL_NODE_MARGIN * 2;
                            let textAngle = Math.atan2(relLink.y, relLink.x);
                            // maintain label vertical orientation for legibility
                            if (textAngle > Math.PI / 2) textAngle = -(Math.PI - textAngle);
                            if (textAngle < -Math.PI / 2) textAngle = -(-Math.PI - textAngle);
                            // const label = link.linkType;
                            const label = link.linkLabel;
            
                            // estimate fontSize to fit in link length
                            ctx.font = '1px Sans-Serif';
                            const fontSize = Math.min(MAX_FONT_SIZE, maxTextLength / ctx.measureText(label).width);
                            ctx.font = `${fontSize}px Sans-Serif`;
                            const textWidth = ctx.measureText(label).width;
                            const bckgDimensions = [textWidth, fontSize]?.map(n => n + fontSize * 0.2); // some padding
                            // draw text label (with background rect)
                            ctx.save();
                            ctx.translate(textPos.x, textPos.y);
                            ctx.rotate(textAngle);
                            ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
            
                            ctx.fillRect(- bckgDimensions[0] / 2, - bckgDimensions[1] / 2, ...bckgDimensions);
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            ctx.fillStyle = 'darkgrey';
                            // ctx.setLineDash([]);
                            
                            ctx.fillText(label, 0, 0);
                            ctx.restore();
                        }}
            
                        
                        />
                        </div>
                    }
        </Show>
    );
};
