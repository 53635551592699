import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi'

import { useShow, IResourceComponentsProps, useOne, useNotification } from "@refinedev/core";

import { TagField, Show, DateField, useModalForm } from "@refinedev/antd";

import { Avatar, Button, Checkbox, Divider, Form, Input, List, Modal, Space, Tooltip, Typography } from "antd";

import { IAddress, ITransaction } from "interfaces";

const { Title, Text } = Typography;
import axios from 'axios';

import { recoverMessageAddress } from 'viem'
import type { Hex } from 'viem'
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";


export const AddressesShow: React.FC<IResourceComponentsProps> = () => {
    const navigate = useNavigate();
    const { address } = useAccount()
    const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const cdn_domain_name = process.env.REACT_APP_CDN_URL
    
    const local_storage_prefix = "DASHBOARD-" + process.env.REACT_APP_ENV + "-" + user?.[process.env.REACT_APP_BASE_URL + '/org_id'] + "-" + user?.[process.env.REACT_APP_BASE_URL + '/tenant_id'] + "-" + user?.[process.env.REACT_APP_BASE_URL + '/sub']
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')
  
    const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"]
    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
    const region = region_config?.region
    const region_alias = region_config?.region_alias
    const backup_region = region_config?.backup_region
    const backup_region_alias = region_config?.backup_region_alias
    const domain = process.env.REACT_APP_API_BASE_URL 
  
    let use_region:string
    let use_region_alias
    let use_backup_region
    let use_backup_region_alias
  
    if(storage_use_region_alias && storage_use_region_alias != "default" && storage_use_region_alias != undefined){
        if(storage_use_region_alias == region_alias){
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias                   
        } else{
            // Switch
            use_backup_region = region
            use_backup_region_alias = storage_use_region_alias
            use_region = backup_region
            use_region_alias = backup_region_alias
        }
    } else{
        // Switch region based on time/minutes. To semi-randomly switch to backup region
        const now = new Date().getUTCMinutes() % 5 // get remainder of 5 minutes interval and check if this is greater than something. First 3 minutes 1 region. Remainder 2 minutes other region
        if(now > 2){ // 3 and 4
            use_backup_region = region_alias 
            use_backup_region_alias = region_alias 
            use_region = backup_region
            use_region_alias = backup_region_alias
            console.log("Using back up region: " + use_region_alias) 
        }
        else{ // 0,1,2
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias
            console.log("Using main region: " + use_region_alias) 
        }
    }
  
    const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
    const qa_environment = qa_config["environment"]
    const blue_config = qa_config["config"]

    const { queryResult } = useShow<IAddress>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const available_chains = [
        {"label": "eth", "value": true}, 
        {"label": "goerli", "value": true},
        {"label": "sepolia", "value": true},
        {"label": "polygon", "value": true},
        {"label": "mumbai", "value": true},
        {"label": "bsc", "value": true},
        {"label": "bsc_testnet", "value": true},
        {"label": "avalanche", "value": true},
        {"label": "fantom", "value": true},
        {"label": "cronos", "value": true},
        {"label": "palm", "value": true},
        {"label": "arbitrum", "value": true}
    ]

    const [fromBlock, setFromBlock] = useState(0);
    const [toBlock, setToBlock] = useState(999999999999);
    const [selectedChains, setSelectedChains] = useState(available_chains);

    const { open } = useNotification();

    const { data: categoryData, isLoading: categoryIsLoading } =
        useOne<IAddress>({
            resource: "addresses",
            id: record?.id || "",
            queryOptions: {
                enabled: !!record,
            },
        });

    // Create Display columns Modal
    const {
        modalProps: createColumnsModalProps,
        formProps: createColumnsFormProps,
        show: createColumnsModalShow,
        formLoading: createColumnsFormLoading,
        onFinish,
        close,
    } = useModalForm({
        warnWhenUnsavedChanges: false,
        action: "create",
        syncWithLocation: false,
        resource: "addresses",

    });

    const [isLoadingReProcessTransactionsForAddress, setIsLoadingReProcessTransactionsForAddress] = React.useState<boolean>(false);

        
    async function ReProcessTransactionsForAddress(address_id: string) {
        setIsLoadingReProcessTransactionsForAddress(true)
        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": use_region,
            "Destination-Region": use_region,
        };

        var chainsString = ''
        selectedChains.forEach((chain: any) => {
            if (chain.value == true){
                chainsString = chainsString + "&chains=" + chain.label
            }
        });

        const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/management/blue/GET/${continent.toUpperCase()}/v0/addresses/${address_id}/process-transactions?from_block=${fromBlock}&to_block=${toBlock}${chainsString}`
        }  
        else{
            url = `${apiUrl}/management/GET/${continent.toUpperCase()}/v0/addresses/${address_id}/process-transactions?from_block=${fromBlock}&to_block=${toBlock}${chainsString}`
        }
        console.log(url)
        return fetch(url, {headers: requestHeaders})
            .then((response) => 
            response.json() 
            )
            .then((body) => {
            if(body.statusCode == 500 || body.statusCode == 400 || body.statusCode == 401 || body.statusCode == 403){
                console.log(body)                       
                setIsLoadingReProcessTransactionsForAddress(false)
                open?.({
                    type: "error",
                    message: "error: " + body.statusCode,
                    description: "error: " + body?.error?.reason ? body?.error?.reason : body?.error?.message, // reason : "Chain is not enabled by org: goerli"
                    key: "address",
                })
            }
            else{
                console.log(body) 
                setIsLoadingReProcessTransactionsForAddress(false)
                open?.({
                    type: "success",
                    message: "Successfully requested the reprocess of the transactions for address",
                    description: "Successfully requested the reprocess of the transactions for address",
                    key: "address",
                })
            }
            }
            )            
            .catch(() =>
                setIsLoadingReProcessTransactionsForAddress(false)
            ).catch(() =>
                open?.({
                    type: "error",
                    message: "error",
                    description: "error",
                    key: "address",
                })
            )
        }

        function toggleChain(e:any) {
            const chain_name = e.target.id
            console.log("Toggling chain " + chain_name )
            for (var chain of selectedChains) {
                if (chain.label == chain_name) {
                    chain.value = e.target.checked
                }
            }    
            setSelectedChains(selectedChains)
        }

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>Id</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>Address</Title>
            <Text>{record?.address}</Text>   

            <Title level={5}>Status</Title>
            <Text>{record?.status}</Text>   
            <Title level={5}>Created at</Title>
            <DateField value={dayjs.unix(record?.created_at || 1)} format="LLL"></DateField>
            <Title level={5}>Created by</Title>
            <Text>{record?.created_by}</Text>   
            <Title level={5}>Tags</Title>
            {
                record?.tags?.map((tag: any) => <TagField id={tag.name} key={tag.name} value={tag.name} color={"#" + tag.color} style={{ marginTop: 5, marginBottom: 5 }}></TagField>)
            }
            <Title level={5}>All Labels</Title>
            {
                record?.labels?.map((label: any) => <TagField id={label.label} key={label.label} value={label.label} color={"#" + label.value} style={{ marginTop: 5, marginBottom: 5 }}></TagField>)
            }
            <Title level={5}>Default Labels</Title>
            {
                record?.default_labels?.map((label: any) => <TagField id={label.label} key={label.label} value={label.label} color={"#" + label.value} style={{ marginTop: 5, marginBottom: 5 }}></TagField>)
            }            
            <Title level={5}>Main Labels</Title>     
            {
                record?.main_labels_grouped_by_chain?.map((label: any) => <Tooltip title={"Main label on chain(s): " + label.chains.join(" + ")}> <TagField id={label.label} key={label.label} value={label.label} color={"#" + label.value} style={{ marginTop: 5, marginBottom: 5 }}></TagField></Tooltip>)
            }
            <Title level={5}>Shared with</Title>
            {
                record?.shared_with?.map((shared_with: any) => <TagField id={shared_with} key={shared_with} value={shared_with} style={{ marginTop: 5, marginBottom: 5 }}></TagField>)
            }
            <Title level={5}>Notes</Title>
            <Text>{record?.notes}</Text>   
            <Title level={5}>Number of transactions for address:</Title>
            <List
                dataSource={record?.transaction_assignments ? record?.transaction_assignments : []}
                renderItem={(item, index) => (
                    <List.Item key={index}>
                    <List.Item.Meta
                        avatar={<Avatar src={`https://${cdn_domain_name}/public/GET/cdn/blockchain/logos/svg/${item?.chain_id}.svg`} />}
                        title={item.chain}
                        description={"Total: " + item.total}
                    />
                    <Space>
                            <Text>{"Outgoing transactions count: " + item.from_address_count}</Text>
                            <Text>{"Incoming transactions count: " + item.to_address_count}</Text>
                            <Text>{"Total transactions count: " + item.total}</Text>
                        </Space>
                    </List.Item>
                )}
                />          
            <Divider></Divider>
            <Button loading={isLoadingReProcessTransactionsForAddress} type="primary" onClick={(e) => createColumnsModalShow()} 
                    >Reprocess the transactions for this address
            </Button>
            <Modal  {...createColumnsModalProps} title="Import transactions for address details">
                <Form key="checkboxForm" {...createColumnsFormProps} layout="vertical" onFinish={(e) => ReProcessTransactionsForAddress(record?.id!)}>
                    <Form.Item 
                            label="From Block"
                            name="from_block"
                            initialValue={0}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input onChange={(e) => setFromBlock(Number(e.target.value))}/>
                    </Form.Item>  
                    <Form.Item 
                            label="To Block"
                            name="to_block"
                            initialValue={999999999999}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input onChange={(e) => setToBlock(Number(e.target.value))}/>
                    </Form.Item>  
                    <Text>Select Chains to import from</Text>
                    {
                        
                        available_chains.map(chain => {
                            return <Form key={chain.label}>
                                <Form.Item key={chain.label} id={chain.label} name={chain.label}>
                                    <Checkbox key={chain.label} id={chain.label} onChange={(e) => {toggleChain(e)}} defaultChecked={chain.value}>{chain.label} </Checkbox>
                                </Form.Item>
                            </Form>
                        })
                    }
                </Form>
            </Modal>
        </Show>
    );
};
