import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import {
  Layout as AntdLayout,
  Space,
  Tag,
  theme,
  Avatar,
  Select,
} from "antd";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import React from "react";

const { useToken } = theme;

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  sticky,
}) => {
  // const { data: user } = useGetIdentity<IUser>();
  const {
    user,
  } = useAuth0();
  
  const { token } = useToken();
  const region_config = user?.[process.env.REACT_APP_BASE_URL + "/region_config"] 
  const region_alias = region_config?.region_alias
  const backup_region_alias = region_config?.backup_region_alias
  const local_storage_prefix = "DASHBOARD-" + process.env.REACT_APP_ENV + "-" + user?.sub + "-" + user?.[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + user?.[process.env.REACT_APP_BASE_URL + "/tenant_id"]   
  
  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
  };

  if (sticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }

  const { Option } = Select;

  let [useRegionAlias, setUseRegionAlias] = React.useState<string | null>(JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')  || "default")

  const handleOnChangeSelectUseRegionAlias = (e: any) => {     
    console.log("Changed region to: " + e)

    if(!e){
      setUseRegionAlias(null)
      localStorage.removeItem(local_storage_prefix + '_use_region_alias')
      window.location.reload();
    } else{
      setUseRegionAlias(e.target)
      localStorage.setItem(local_storage_prefix + '_use_region_alias', JSON.stringify(e))
      window.location.reload();
    }

  };
  
  return (
    <AntdLayout.Header style={headerStyles}>
      <Select 
          defaultValue={useRegionAlias}
          placeholder="Please select a region"
          onChange={handleOnChangeSelectUseRegionAlias}
          >
          <Option value="pdx" disabled={"pdx" != region_alias && "pdx" != backup_region_alias}><b>pdx</b> - us-west-2</Option>
          <Option value="iad" disabled={"iad" != region_alias && "iad" != backup_region_alias}><b>iad</b>  - us-east-1</Option>
          <Option value="fra" disabled={"fra" != region_alias && "fra" != backup_region_alias}><b>fra</b>  - eu-central-1</Option>
          <Option value="dub" disabled={"dub" != region_alias && "dub" != backup_region_alias}><b>dub</b>  - eu-west-1</Option>
          <Option value="default"><b>Default</b>  - default</Option>
      </Select>         
      <Space>
        <ConnectButton />
      </Space>
      <Link to="/settings">
      <Space
                style={{
                    float: "left",
                    marginRight: "10px",
                    paddingLeft: "10px"
                }}
            >
              <strong>{user?.name}</strong>
            </Space>   
              <Space
                    style={{
                        float: "left",
                        marginRight: "10px",
                        paddingLeft: "0px"
                    }}
                >
                <Avatar src={<img src={user?.picture} alt="avatar" />} />
              </Space>
            </Link>      
            <Space>
            <Tag color="geekblue">{user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']?.length > 1 ? "Custom" : user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']?.[0]?.name || "Error: No Role found"}</Tag>
            </Space>   
            <Tag color="success">{user?.[process.env.REACT_APP_BASE_URL + '/tenant']?.['is_root_tenant'] ? "" : user?.[process.env.REACT_APP_BASE_URL + '/tenant']?.name  || ""}</Tag>
            {user?.[process.env.REACT_APP_BASE_URL + '/qa_config']?.["environment"] == "blue" ? <Tag color="darkblue">blue</Tag> : null}
            {
              user?.[process.env.REACT_APP_BASE_URL + '/custom_app_config']?.['is_demo_account'] ? <Tag color="red">DEMO ACCOUNT</Tag> 
              : ""
            }
    </AntdLayout.Header>
  );
};
