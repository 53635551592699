import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Link } from 'react-router-dom';
import { isAddress } from 'viem'
import dayjs from "dayjs";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { 
    Row,
    Col,
    Tag,
    Layout as AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
    Tabs, 
    TabPane,  
    Space,
    Tooltip,
    Avatar,
    Modal,
    Table,
    Select,
    Spin,
    ColorPicker,
    Divider,
  } from 'antd';
import RoleAndPermissions from './components/profile'
import { Show, MarkdownField,  List, TextField,
  FilterDropdown, EditButton, ShowButton, Edit, DeleteButton, 
  useTable, useModalForm, DateField, getValueFromEvent} from "@refinedev/antd";

  import {
    Upload,
  } from "antd";

const { Text, Title } = Typography;

import {
  FieldTimeOutlined,
  AuditOutlined,
  BarChartOutlined, 
  DashboardOutlined,
  AlignLeftOutlined,
  ExperimentOutlined,
  SettingOutlined
} from "@ant-design/icons";

import { useNotification } from "@refinedev/core";
// import { IUser } from "interfaces";


export default function Settings() {
  const API_URL = process.env.REACT_APP_API_URL || ""
  const { open } = useNotification();

  const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const TabPane = Tabs.TabPane
  const local_storage_prefix = "DASHBOARD-" + process.env.REACT_APP_ENV + "-" + user[process.env.REACT_APP_BASE_URL + '/org_id'] + "-" + user[process.env.REACT_APP_BASE_URL + '/tenant_id'] + "-" + user[process.env.REACT_APP_BASE_URL + '/sub']

  const token = JSON.parse(localStorage.getItem('token') || '{}');
  let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')

  const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"]
  const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
  const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
  const region = region_config?.region
  const region_alias = region_config?.region_alias
  const backup_region = region_config?.backup_region
  const backup_region_alias = region_config?.backup_region_alias
  const domain = process.env.REACT_APP_API_BASE_URL 

  let use_region
  let use_region_alias
  let use_backup_region
  let use_backup_region_alias

  if(storage_use_region_alias && storage_use_region_alias != "default" && storage_use_region_alias != undefined){
      if(storage_use_region_alias == region_alias){
          use_backup_region = backup_region
          use_backup_region_alias = backup_region_alias
          use_region = region
          use_region_alias = region_alias                   
      } else{
          // Switch
          use_backup_region = region
          use_backup_region_alias = storage_use_region_alias
          use_region = backup_region
          use_region_alias = backup_region_alias
      }
  } else{
      // Switch region based on time/minutes. To semi-randomly switch to backup region
      const now = new Date().getUTCMinutes() % 5 // get remainder of 5 minutes interval and check if this is greater than something. First 3 minutes 1 region. Remainder 2 minutes other region
      if(now > 2){ // 3 and 4
          use_backup_region = region_alias 
          use_backup_region_alias = region_alias 
          use_region = backup_region
          use_region_alias = backup_region_alias
          console.log("Using back up region: " + use_region_alias) 
      }
      else{ // 0,1,2
          use_backup_region = backup_region
          use_backup_region_alias = backup_region_alias
          use_region = region
          use_region_alias = region_alias
          console.log("Using main region: " + use_region_alias) 
      }
  }

  const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
  const qa_environment = qa_config["environment"]
  const blue_config = qa_config["config"]

  const requestHeaders = {
      Authorization: `Bearer ${token.__raw}`,
      Accept: "application/json, text/plain, */*",
      "Source-Platform": "dashboard",
      "Source-Region": use_region,
      "Destination-Region": use_region,
      "filename": "testhere.png"
  };

  const apiUrl = "https://" + continent.toLowerCase() + ".api." + domain 
  
  let available_regions = new Map([
    ["US", "US - US West (Oregon) and US East (N. Virginia)"],
    ["EU", "EU - Europe (Frankfurt) and Europe (Ireland)"],
    ["AP", "AP - Asia Pacific (Tokyo) and Asia Pacific (Singapore)"],
    ["IN", "IN - Asia Pacific (Mumbai) and Asia Pacific (Hyderabad)"]
  ]);

  let enabled_chains = [
    {"label": "Ethereum", "value": "0x1"},
    {"label": "Goerli", "value": "0x5" },
    {"label": "Sepolia", "value": "0xaa36a7" },
    {"label": "Polygon", "value": "0x89" },
    {"label": "Mumbai", "value": "0x13881" },
    {"label": "Bsc", "value": "0x38" },
    {"label": "Bsc Testnet", "value": "0x61" },
    {"label": "Avalanche", "value": "0xa86a" },
    {"label": "Fantom", "value": "0xfa" },
    {"label": "Cronos", "value": "0x19" },
    {"label": "Palm", "value": "0x2a15c308d" },
    {"label": "Arbitrum", "value": "0xa4b1" },
    {"label": "Gnosis", "value": "0x64" },
    {"label": "Gnosis Chiado", "value": "0x27d8" },
    {"label": "Chiliz", "value": "0x15b38" },
    {"label": "Chiliz Spicy", "value": "0x15b32" },
    {"label": "Base", "value": "0x2105" },
    {"label": "Base Goerli", "value": "0x14a33" },
  ]

  let scopes = []

  // Sort the scopes alphabetically
  Object.values(user[process.env.REACT_APP_BASE_URL + "/role_permissions"]).forEach(role => {
    let arr = []
    role.scopes.forEach(scope => arr.push(scope))
    scopes = scopes.concat(arr)
    scopes = Array.from(new Set(scopes));
  });
  // Sort the scopes alphabetically
  let arr = []
  scopes.forEach(scope => arr.push(scope))
    scopes = scopes.concat(arr)
    scopes = Array.from(new Set(scopes));

  const [editFamilyName, setEditFamilyName] = React.useState('')
  const [editGivenName, setEditGivenName] = React.useState('')
  const [editOrganizationnName, setEditOrganizationName] = React.useState('')

  function toggleEdit(e) {
    if(e.target.id == "family_name"){
      setEditFamilyName(true)
    }
    if(e.target.id == "given_name"){
      setEditGivenName(true)
    }
    if(e.target.id == "organization_name"){
      setEditOrganizationName(true)
      setEnableOrganizationSaveButton(true)
    }
    console.log(e.target.id)
  }

  const [enableOrganizationSaveButton, setEnableOrganizationSaveButton] = React.useState(false)
  const [organizationName, setOrganizationName] = React.useState(user[process.env.REACT_APP_BASE_URL + '/org']?.name)

  function onOrganizationNamechange(e){
    setOrganizationName(e.target.value)
  }

  async function saveOrganizationDetailsButton(e) {
    console.log(e.target.id)
    console.log(organizationName)
    setEnableOrganizationSaveButton(false)
    if(organizationName != user[process.env.REACT_APP_BASE_URL + '/org']?.name){
      console.log("organization save button clicked")
      
      let url
      if (qa_environment == "blue"){
          url = `${apiUrl}/management/blue/GET/${continent.toUpperCase()}/v0/organizations/${user[process.env.REACT_APP_BASE_URL + '/org_id']}`
      }  
      else{
        url = `${apiUrl}/management/GET/${continent.toUpperCase()}/v0/organizations/${user[process.env.REACT_APP_BASE_URL + '/org_id']}`
      }
      console.log(url)
  
      const { data, status } = await axios.post(
        url,
        {
          "name": organizationName
        }
      ).catch(function (error) {
        console.log(error)

        open?.({
          type: "error",
          message: "Error",
          description: error.response?.data?.error?.message,
          key: "organization_name",
        });

        setEnableOrganizationSaveButton(true)
      })

      console.log("Organization name updated: " + JSON.stringify(data))

    
      if(data.error){
        open?.({
          type: "error",
          message: "Error",
          description: "Unknown error while updating the organization name",
          key: "organization_name",
        });
        setEnableOrganizationSaveButton(true)
      }
      else{
        // Get the new token with the updated org name
        const tenant_id = localStorage.getItem('tenant_id') ? localStorage.getItem('tenant_id') : "root_tenant"
        const access_token = await getAccessTokenSilently( { cacheMode: 'off', authorizationParams: {"tenant_id": tenant_id }});
        
        await getIdTokenClaims({ cacheMode: 'off'});

        open?.({
          type: "success",
          message: "Successfully updated the organization name",
          description: "Successfully updated the organization name",
          key: "organization_name",
        });
        setEditOrganizationName(false)
      }
    }
    else{
        // Get the new token with the updated org name
        const tenant_id = localStorage.getItem('tenant_id') ? localStorage.getItem('tenant_id') : "root_tenant"
        const access_token = await getAccessTokenSilently( { cacheMode: 'off', authorizationParams: {"tenant_id": tenant_id }});
        
        await getIdTokenClaims({ cacheMode: 'off'});

        open?.({
          type: "success",
          message: "Successfully updated the organization name",
          description: "Successfully updated the organization name",
          key: "organization_name",
        });
        setEditOrganizationName(false)
    }
    

  }

  let [changedRoles, setChangedRoles] = React.useState(user[process.env.REACT_APP_BASE_URL + '/roles'])
  
  const handleReloadRoles = async (value) => {
    // console.log(value);

    const token = JSON.parse(localStorage.getItem('token') || '{}');
    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const domain = process.env.REACT_APP_API_BASE_URL

    const url = "https://" + continent.toLowerCase() + ".api." + domain + "/management/permissions/GET/" + continent.toUpperCase() + "/v0/roles"

    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)

      open?.({
        type: "error",
        message: "Error",
        description: error.response?.data?.error?.message,
        key: "roles",
      });
    })

    console.log("Roles retrieved: " + JSON.stringify(data))

  
    if(data.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the roles",
        key: "roles",
      });
    }
    else{
      // Reset array with available roles
      setAvailableRoles([])
      data.result.forEach( role => {
        const obj = {"label": role.role_name, "value": role.id}
        setAvailableRoles(prevState => [...prevState, obj])
      })

      open?.({
        type: "success",
        message: "Successfully retrieved the roles",
        description: "Successfully retrieved the roles",
        key: "roles",
      });
    }
  };

  const handleSelectedRoleChange = (value) => {
    changedRoles = value
    console.log(availableRoles)
    setChangedRoles(value)
  };

  let [availableRoles, setAvailableRoles] = React.useState(JSON.parse(process.env.REACT_APP_DEFAULT_APP_ROLES)["roles"])

  let [isLoadingSubmitRoleChange, setIsLoadingSubmitRoleChange] = React.useState(false)
  const handleSubmitdRoleChange = async (value) => {
    setIsLoadingSubmitRoleChange(true)
    console.log(changedRoles);

    const existing_roles = user[process.env.REACT_APP_BASE_URL + '/roles']
    const roles_to_remove = []
    const roles_to_add = []
    
    changedRoles.forEach(role => {
      // convert role id to role name
      var role_name = ""
      availableRoles.forEach(available_role => {
        if(available_role.value == role){
          role_name = available_role.label
        }
      })

      if (existing_roles.indexOf(role_name) > -1){
        console.log("Role is already there: " + role_name)
      }
      else{
        console.log("Role needs to be added: " + role_name)
        roles_to_add.push(role)
      }
    })

    existing_roles.forEach(role_name => {
      // convert role name to role id
      // If roles are not loaded then custom role id is not available. So this needs to be computed on the backend
      var role_id = ""
      availableRoles.forEach(available_role => {
        if(available_role.label == role_name){
          role_id = available_role.value
        }
      })

      if (changedRoles.indexOf(role_id) > -1){
        console.log("Role is already there: " + role_name)
      }
      else if(role_id == ""){
        console.log("Role needs to be removed: " + role_name)
        roles_to_remove.push(role_name)
      }
      else{
        console.log("Role needs to be removed: " + role_name)
        roles_to_remove.push(role_id)
      }
    })

    console.log("roles to add: " + roles_to_add)
    console.log("roles to remove: " + roles_to_remove)

    const url = `https://${use_region_alias}.${continent}.management.sandbox.authe.io/management/permissions/ANY/${continent.toUpperCase()}/v0/users/` + user.sub + "/roles"

    let error = []
    if(roles_to_add.length > 0){
        const { data, status } = await axios.post(
          url,
          {
            "roles": roles_to_add
          }
      ).catch(function (error) {
        console.log(error)

        open?.({
          type: "error",
          message: "Error",
          description: error.response?.data?.error?.message,
          key: "roles",
        });
      })
    
      console.log("Roles added updated: " + JSON.stringify(data))
      if(data?.error){
        error.push(data?.error)
      }
    }
    
    if(roles_to_remove.length > 0){
      const { data_deleted, status_deleted } = await axios.delete(
        url,
        { data:
          {
            "roles": roles_to_remove
          }
        }
      ).catch(function (error) {
        console.log(error)

        open?.({
          type: "error",
          message: "Error",
          description: error.response?.data?.error?.message,
          key: "roles",
        });
      })

      console.log("Roles removed updated: " + JSON.stringify(data_deleted))
      if(data_deleted?.error){
        error.push(data_deleted?.error)
      }
    }

    if(error.length > 0){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while updating the roles",
        key: "roles",
      });
    }
    else{
      open?.({
        type: "success",
        message: "Roles Updated",
        description: "Roles updated",
        key: "roles",
      });
    }
    
    const tenant_id = localStorage.getItem('tenant_id') ? localStorage.getItem('tenant_id') : "root_tenant"
    const access_token = await getAccessTokenSilently( { cacheMode: 'off', authorizationParams: {"tenant_id": tenant_id }});
    
    await getIdTokenClaims({ cacheMode: 'off'});

    setIsLoadingSubmitRoleChange(false)
  };

  const handleChangeTenant = async (value) => {
    console.log(value.target.id)
    localStorage.setItem('tenant_id', value.target.id);

    await getAccessTokenSilently( { cacheMode: 'off', authorizationParams: {"tenant_id": value.target.id }});

    await getIdTokenClaims({ cacheMode: 'off', authorizationParams: {"tenant_id": value.target.id }}); 
    
    open?.({
      type: "success",
      message: "Successfully switched tenant",
      description: "Successfully switched tenant",
      key: "tenants",
    });

  };

  let [isLoadingGetTenants, setIsLoadingGetTenants] = React.useState(false)
  let [tenants, setTenants] = React.useState([user[process.env.REACT_APP_BASE_URL + "/tenant"]])  

  const getTenants = async (value) => {
    setIsLoadingGetTenants(true)

    console.log("Get tenants")
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const domain = process.env.REACT_APP_API_BASE_URL

    const url = "https://" + continent.toLowerCase() + ".api." + domain + "/management/GET/" + continent.toUpperCase() + "/v0/organizations/" + user[process.env.REACT_APP_BASE_URL + '/org_id'] +  "/tenants" 

    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)
      setIsLoadingGetTenants(false)

      open?.({
        type: "error",
        message: "Error",
        description: error.response?.data?.error?.message,
        key: "tenants",
      });
    })

    console.log("Tenants retrieved: " + JSON.stringify(data))

  
    if(data?.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the tenants",
        key: "users",
      });
      setIsLoadingGetTenants(false)
    }
    else{
      // // Reset array with available roles
      setTenants([])
      data.result.forEach( tenant => {
        const obj = {
          "id": tenant.tenant_id, 
          "name": tenant.tenant_name, 
          "continent": tenant.tenant_continent, 
          "is_root_tenant":  tenant.is_root_tenant ? true : false
        }
        setTenants(prevState => [...prevState, obj])
      })


      open?.({
        type: "success",
        message: "Successfully retrieved the tenants",
        description: "Successfully retrieved the tenants",
        key: "users",
      });
    }
    setIsLoadingGetTenants(false)
  };
  
  // Create Tenant Modal
  const {
      modalProps: createTenantModalProps,
      formProps: createTenantFormProps,
      show: createTenantModalShow,
      formLoading: createTenantFormLoading,
  } = useModalForm({
      action: "create",
      syncWithLocation: true,
      resource:"tenants"
  });
      
  let [isLoadingCreateTenant, setIsLoadingCreateTenants] = React.useState(false)
  const createTenant = async (value) => {
    console.log("Create tenant")
    createTenantModalShow()
  };


  let [organizationMembers, setOrganizationMembers] = React.useState(JSON.parse(localStorage.getItem(local_storage_prefix + '_organization_members')) || [])

  let [isLoadingGetOrganizationMembers, setIsLoadingGetOrganizationMembers] = React.useState(false)
  const getOrganizationMembers = async () => {
    setIsLoadingGetOrganizationMembers(true)
    console.log("Get members");

    const url = "https://us.api.sandbox.authe.io/management/permissions/GET/US/v0/users"

    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)
      setIsLoadingGetOrganizationMembers(false)

      open?.({
        type: "error",
        message: "Error",
        description: error.response?.data?.error?.message,
        key: "users",
      });
    })

    console.log("Organization members retrieved: " + JSON.stringify(data))

  
    if(data.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the organization members",
        key: "users",
      });
    }
    else{
      // Reset array with available roles
      setOrganizationMembers([])
      data.result.forEach( user => {
        const obj = {
          "id": user.id, 
          "name": user.name, 
          "email": user.email, 
          "roles": user.roles ? user.roles.join(', ') : "None" , 
          "status": user.status, 
        }
        setOrganizationMembers(prevState => [...prevState, obj])
      })


      open?.({
        type: "success",
        message: "Successfully retrieved the organization members",
        description: "Successfully retrieved the organization members",
        key: "users",
      });
    }

    setIsLoadingGetOrganizationMembers(false)
  }
  
    // Create Invite org member Modal
    const {
      modalProps: createInviteOrgMemberModalProps,
      formProps: createInviteOrgMemberFormProps,
      show: createInviteOrgMemberModalShow,
      formLoading: createInviteOrgMemberFormLoading,
  } = useModalForm({
      action: "create",
      syncWithLocation: true,
      resource:"users/invite"
  });

  const inviteOrgMember = async (value) => {
    console.log("Invite org member")
    createInviteOrgMemberModalShow()
  };

  
  let [foundAddresses, setFoundAddresses] = React.useState(JSON.parse(localStorage.getItem(local_storage_prefix + '_my_addresses')) || [])

  let [lastGetAddressOperationUsed, setLastGetAddressOperationUsed] = React.useState("")

  const getAddresses = async () => {
    if(lastGetAddressOperationUsed == "getUserAddresses"){
      getTenantAddresses()
    }else{
      getUserAddresses()
    }
  }
  

  let [isLoadingGetTenantAddresses, setIsLoadingGetTenantAddresses] = React.useState(false)
  const getTenantAddresses = async () => {
    setIsLoadingGetTenantAddresses(true)
    console.log("Get tenant addresses");
    setLastGetAddressOperationUsed("getTenantAddresses")

    console.log("Sending the request")
    let url
    if (qa_environment == "blue"){
        url = `${apiUrl}/management/blue/GET/${continent.toUpperCase()}/v0/addresses?scope=tenant`
    }  
    else{
      url = `${apiUrl}/management/GET/${continent.toUpperCase()}/v0/addresses?scope=tenant`
    }
    console.log(url)

    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)
      setIsLoadingGetTenantAddresses(false)
      open?.({
        type: "error",
        message: "Error",
        description: error.response?.data?.error?.message,
        key: "tenant_addresses",
      });
    })

    console.log("Tenant addresses retrieved: " + JSON.stringify(data))

  
    if(data.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the tenant addresses",
        key: "tenant_addresses",
      });
    }
    else{
      // Reset array
      setFoundAddresses([])
      data.result.forEach( address => {
        const obj = {
          "id": address.id, 
          "address": address.address, 
          "created_at": address.created_at,
          "created_by": address.created_by,
          "org_id": address.org_id,
          "tenant_id": address.tenant_id,
          "tags": address.tags,
          "default_labels": address.default_labels,
          "labels": address.labels,
          "shared_with": address.shared_with
        }
        setFoundAddresses(prevState => [...prevState, obj])
      })

      open?.({
        type: "success",
        message: "Successfully retrieved the tenant addresses",
        description: "Successfully retrieved the tenant addresses",
        key: "tenant_addresses",
      });
    }

    setIsLoadingGetTenantAddresses(false)
  }


  let [isLoadingGetUserAddresses, setIsLoadingGetUserAddresses] = React.useState(false)
  const getUserAddresses = async () => {
    setIsLoadingGetUserAddresses(true)
    console.log("Get user addresses");
    setLastGetAddressOperationUsed("getUserAddresses")
    
    console.log("Sending the request")
    let url
    if (qa_environment == "blue"){
        url = `${apiUrl}/management/blue/GET/${continent.toUpperCase()}/v0/addresses?scope=user`
    }  
    else{
      url = `${apiUrl}/management/GET/${continent.toUpperCase()}/v0/addresses?scope=user`
    }
    console.log(url)

    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)
      setIsLoadingGetUserAddresses(false)
      open?.({
        type: "error",
        message: "Error",
        description: error.response?.data?.error?.message,
        key: "user_addresses",
      });
    })

    console.log("User addresses retrieved: " + JSON.stringify(data))

  
    if(data.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the user addresses",
        key: "user_addresses",
      });
    }
    else{
      // Reset array
      setFoundAddresses([])
      data.result.forEach( address => {
        const obj = {
          "id": address.id, 
          "address": address.address, 
          "created_at": address.created_at,
          "created_by": address.created_by,
          "org_id": address.org_id,
          "tenant_id": address.tenant_id,
          "tags": address.tags,
          "labels": address.labels,
          "default_labels": address.default_labels,
          "shared_with": address.shared_with,
          "notes": address.notes
        }
        setFoundAddresses(prevState => [...prevState, obj])
      })

      open?.({
        type: "success",
        message: "Successfully retrieved the user addresses",
        description: "Successfully retrieved the user addresses",
        key: "user_addresses",
      });
    }

    setIsLoadingGetUserAddresses(false)
  }

    // Add address Modal
    const {
      modalProps: addAddressModalProps,
      formProps: addAddressFormProps,
      show: addAddressModalShow,
      formLoading: addAddressFormLoading,
  } = useModalForm({
      action: "create",
      syncWithLocation: true,
      resource:"addresses",
      onMutationSuccess: (data, variables, context, isAutoSave) => {
        getAddresses()
    },
  });

  const addAddress = async (value) => {
    console.log("Add address")
    addAddressModalShow()
  };

  let [isLoadingGetLabels, setIsLoadingGetLabels] = React.useState(false)
  let [labels, setLabels] = React.useState(JSON.parse(localStorage.getItem(local_storage_prefix + '_labels')) || [])  
  let [showAddressLabelFields, setShowAddressLabelFields] = React.useState(false)
  let [isMainAddressLabel, setIsMainAddressLabel] = React.useState(false)
  let [labelAddress, setLabelAddress] = React.useState("")
  let [labelChains, setLabelChains] = React.useState([])
  let [mainLabelChains, setMainLabelChains] = React.useState([])

  const getLabels = async (value) => {
    setIsLoadingGetLabels(true)

    console.log("Get labels")
        
    console.log("Sending the request")
    let url
    if (qa_environment == "blue"){
        url = `${apiUrl}/private/labels/blue/GET/${continent.toUpperCase()}/v0/labels`
    }  
    else{
      url = `${apiUrl}/private/labels/GET/${continent.toUpperCase()}/v0/labels`
    }
    console.log(url)

    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)
      setIsLoadingGetLabels(false)

      open?.({
        type: "error",
        message: "Error",
        description: error.response?.data?.error?.message,
        key: "labels",
      });
    })

    console.log("Labels retrieved: " + JSON.stringify(data))

  
    if(data?.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the labels",
        key: "labels",
      });
      setIsLoadingGetLabels(false)
    }
    else{
      // // Reset array with available roles
      setLabels([])
      data.result.forEach( label => {
        let obj
        if (label.address) {
           obj = {
            "id": label.id, 
            "name": label.name, 
            "color": label.color, 
            "address": label.address, 
            "source": label.source, 
            "is_main_label": label.is_main_label, 
            "chains": label.chains, 
            "main_label_chains": label.main_label_chains, 
          }
        } else{
          obj = {
            "id": label.id, 
            "name": label.name, 
            "color": label.color, 
          }
        }
        setLabels(prevState => [...prevState, obj])
      })


      open?.({
        type: "success",
        message: "Successfully retrieved the labels",
        description: "Successfully retrieved the labels",
        key: "labels",
      });
    }
    setIsLoadingGetLabels(false)
  };
  
  // Create Label Modal
  const {
      modalProps: createLabelModalProps,
      formProps: createLabelFormProps,
      show: createLabelModalShow,
      formLoading: createLabelFormLoading,
      onFinish: onFinishCreateLabel,
      close: closeCreateLabel,
  } = useModalForm({
      action: "create",
      syncWithLocation: true,
      resource:"labels",
      warnWhenUnsavedChanges: false,
      autoResetForm: true,
      onMutationSuccess: (data, variables, context, isAutoSave) => {
        closeCreateLabel();
        getLabels()
      },
  });
      
  let [isLoadingCreateLabel, setIsLoadingCreateLabel] = React.useState(false)
  const createLabel = async (value) => {
    console.log("Create label")

    createLabelModalShow()
  };

  const onFinishHandlerCreateLabel = (values ) => {
    console.log(values)
    let new_obj = {}
    if(showAddressLabelFields){
      new_obj = {
        name: values.label,
        color: values.color.toHex(),
        source: values.image?.[0].response?.url,
        address: labelAddress,
        chains: labelChains,
        main_label_chains: mainLabelChains,
        is_address_label: showAddressLabelFields,
        is_main_label: isMainAddressLabel
      }
    } else{
      new_obj = {
        name: values.label,
        color: values.color.toHex(),
      }
    }

    console.log(new_obj)
    onFinishCreateLabel?.(new_obj);
  }
  
  let [editLabelId, setEditLabelId] = React.useState("")

  // Edit Label Modal
  const {
      modalProps: editLabelModalProps,
      formProps: editLabelFormProps,
      show: editLabelModalShow,
      formLoading: editLabelFormLoading,
      onFinish: OnFinishEditLabel,
      close: closeEditLabel,
  } = useModalForm({
      action: "update",
      syncWithLocation: true,
      resource:"labels",
      id: editLabelId,
      onMutationSuccess: (data, variables, context, isAutoSave) => {
        closeEditLabel();
        getLabels()
      },
  });
  
  const onFinishHandlerEditLabel = (values ) => {
    console.log(values)
    OnFinishEditLabel?.({
        id: editLabelId,
        name: editLabelName,
        color: editLabelColor,
        source: values.image ? values.image[0].response?.url : editLabelSource,
    });
  }

  let [isLoadingEditLabel, setIsLoadingEditLabel] = React.useState(false)
  let [editLabelColor, setEditLabelColor] = React.useState("")
  let [editLabelName, setEditLabelName] = React.useState("")
  let [editIsAddressLabel, setEditIsAddressLabel] = React.useState(false)
  let [editAddressLabel, setEditAddressLabel] = React.useState("")
  let [editLabelSource, setEditLabelSource] = React.useState("")
  let [editLabelIsMainAddressLabel, setEditLabelIsMainAddressLabel] = React.useState("")
  let [editLabelChains, setEditLabelChains] = React.useState([])
  let [editMainLabelChains, setEditMainLabelChains] = React.useState([])

  const editLabel = async (value, record) => {
    console.log("Edit label")
    console.log(record)
    setEditLabelColor(prev => record.color)
    setEditLabelName(prev => record.name)
    setEditLabelId(prev => record.id)
    if(record.address){
      setEditIsAddressLabel(true)
      setEditAddressLabel(prev => record.address)
      setEditLabelSource(prev => record.source)
      setEditLabelIsMainAddressLabel(prev => record.is_main_label)
      setEditLabelChains(prev => record.chains)
      setEditMainLabelChains(prev => record.main_label_chains)

    }

    editLabelModalShow()
  };

  const handleLabelNameChange = async (e) => {
    setEditLabelName(e.target.value)
  };
  
  const handleLabelColorChange = async (e) => {
    console.log(e)
    setEditLabelColor(e.metaColor.toHex())
  };


  let [isLoadingGetTags, setIsLoadingGetTags] = React.useState(false)
  let [tags, setTags] = React.useState(JSON.parse(localStorage.getItem(local_storage_prefix + '_tags')) || [])  

  const getTags = async (value) => {
    setIsLoadingGetTags(true)

    console.log("Get tags")

    let url
    if (qa_environment == "blue"){
        url = `${apiUrl}/private/tags/blue/GET/${continent.toUpperCase()}/v0/tags`
    }  
    else{
      url = `${apiUrl}/private/tags/GET/${continent.toUpperCase()}/v0/tags`
    }
    console.log(url)

    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)
      setIsLoadingGetTags(false)

      open?.({
        type: "error",
        message: "Error",
        description: error.response?.data?.error?.message,
        key: "tags",
      });
    })

    console.log("Tags retrieved: " + JSON.stringify(data))

  
    if(data?.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the tags",
        key: "tags",
      });
      setIsLoadingGetTags(false)
    }
    else{
      // // Reset array with available roles
      setTags([])
      data.result.forEach( tag => {
        const obj = {
          "id": tag.id, 
          "name": tag.name, 
          "color": tag.color, 
        }
        setTags(prevState => [...prevState, obj])
      })


      open?.({
        type: "success",
        message: "Successfully retrieved the tags",
        description: "Successfully retrieved the tags",
        key: "tags",
      });
    }
    setIsLoadingGetTags(false)
  };
  
  // Create Tag Modal
  const {
      modalProps: createTagModalProps,
      formProps: createTagFormProps,
      show: createTagModalShow,
      formLoading: createTagFormLoading,
      onFinish: onFinishCreateTag,
      close: closeCreateTag,
      
  } = useModalForm({
      action: "create",
      syncWithLocation: true,
      resource:"tags",
      warnWhenUnsavedChanges: false,
      autoResetForm: true,
      onMutationSuccess: (data, variables, context, isAutoSave) => {
        closeCreateTag();
        getTags()
      },
  });
      
  let [isLoadingCreateTag, setIsLoadingCreateTag] = React.useState(false)
  const createTag = async (value) => {
    console.log("Create tag")
    createTagModalShow()
  };

  const onFinishHandlerCreateTag = (values ) => {
    console.log(values)
    onFinishCreateTag?.({
        name: values.tag,
        color: values.color.toHex(),
    });
  }

  let [editTagId, setEditTagId] = React.useState("")

  // Edit Tag Modal
  const {
      modalProps: editTagModalProps,
      formProps: editTagFormProps,
      show: editTagModalShow,
      formLoading: editTagFormLoading,
      onFinish: onFinishEditTag,
      close: closeEditTag,
  } = useModalForm({
      action: "update",
      syncWithLocation: true,
      resource:"tags",
      id: editTagId,
      onMutationSuccess: (data, variables, context, isAutoSave) => {
        closeEditTag();
        getTags()
      },
  });
  
  const onFinishHandlerEditTag = (values ) => {
    console.log(values)
    onFinishEditTag?.({
        id: editTagId,
        name: editTagName,
        color: editTagColor,
    });
  }

  let [isLoadingEditTag, setIsLoadingEditTag] = React.useState(false)
  let [editTagColor, setEditTagColor] = React.useState("")
  let [editTagName, setEditTagName] = React.useState("")

  const editTag = async (value, record) => {
    console.log("Edit tag")
    setEditTagColor(prev => record.color)
    setEditTagName(prev => record.name)
    setEditTagId(prev => record.id)
    editTagModalShow()
  };

  const handleTagNameChange = async (e) => {
    setEditTagName(e.target.value)
  };
  
  const handleTagColorChange = async (e) => {
    console.log(e)
    setEditTagColor(e.metaColor.toHex())
  };


  let [isLoadingGetGroups, setIsLoadingGetGroups] = React.useState(false)
  let [groups, setGroups] = React.useState(JSON.parse(localStorage.getItem(local_storage_prefix + '_groups')) ||[])  

  const getGroups = async (value) => {
    setIsLoadingGetGroups(true)

    console.log("Get groups")

    let url
    if (qa_environment == "blue"){
        url = `${apiUrl}/private/groups/blue/GET/${continent.toUpperCase()}/v0/groups`
    }  
    else{
      url = `${apiUrl}/private/groups/GET/${continent.toUpperCase()}/v0/groups`
    }
    console.log(url)
    
    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)
      setIsLoadingGetGroups(false)

      open?.({
        type: "error",
        message: "Error",
        description: error.response?.data?.error?.message,
        key: "groups",
      });
    })

    console.log("Groups retrieved: " + JSON.stringify(data))

  
    if(data?.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the groups",
        key: "groups",
      });
      setIsLoadingGetGroups(false)
    }
    else{
      // // Reset array with available roles
      setGroups([])
      data.result.forEach( group => {
        const obj = {
          "id": group.id, 
          "name": group.name, 
          "color": group.color, 
        }
        setGroups(prevState => [...prevState, obj])
      })



      open?.({
        type: "success",
        message: "Successfully retrieved the groups",
        description: "Successfully retrieved the groups",
        key: "groups",
      });
    }
    setIsLoadingGetGroups(false)
  };
  
  let [isLoadingGetSharedGraphs, setIsLoadingGetSharedGraphs] = React.useState(false)
  let [sharedGraphs, setSharedGraphs] = React.useState(JSON.parse(localStorage.getItem(local_storage_prefix + '_shared_graphs')) ||[])  

  const getSharedGraphs = async (value) => {
    setIsLoadingGetSharedGraphs(true)

    console.log("Get shared graphs")

    let url
    if (qa_environment == "blue"){
        url = `${apiUrl}/private/analytics/shared-transactions-graphs/blue/GET/${continent.toUpperCase()}/v0/shared-graphs`
    }  
    else{
      url = `${apiUrl}/private/analytics/shared-transactions-graphs/GET/${continent.toUpperCase()}/v0/shared-graphs`
    }
    console.log(url)
    
    const { data, status } = await axios.get(
      url,
    ).catch(function (error) {
      console.log(error)
      setIsLoadingGetSharedGraphs(false)

      open?.({
        type: "error",
        message: "Error",
        description: error.response?.data?.error?.message,
        key: "shared_graphs",
      });
    })

    console.log("Shared graphs retrieved: " + JSON.stringify(data))

  
    if(data?.error){
      open?.({
        type: "error",
        message: "Error",
        description: "Unknown error while getting the shared graphs",
        key: "shared_graphs",
      });
      setIsLoadingGetSharedGraphs(false)
    }
    else{
      // // Reset array with available roles
      setSharedGraphs([])
      data.result.forEach( shared_graph => {
        const obj = {
          "id": shared_graph.id, 
          "name": shared_graph.name, 
        }
        setSharedGraphs(prevState => [...prevState, obj])
      })



      open?.({
        type: "success",
        message: "Successfully retrieved the shared graphs",
        description: "Successfully retrieved the shared graohs",
        key: "groups",
      });
    }
    setIsLoadingGetSharedGraphs(false)
  };

  // Create Group Modal
  const {
      modalProps: createGroupModalProps,
      formProps: createGroupFormProps,
      show: createGroupModalShow,
      formLoading: createGroupFormLoading,
      onFinish: onFinishCreateGroup,
      close: closeCreateGroup,
  } = useModalForm({
      action: "create",
      syncWithLocation: true,
      resource:"groups",
      warnWhenUnsavedChanges: false,
      autoResetForm: true,
      onMutationSuccess: (data, variables, context, isAutoSave) => {
        closeCreateGroup();
        getGroups()
      },
  });
      
  let [isLoadingCreateGroup, setIsLoadingCreateGroup] = React.useState(false)
  const createGroup = async (value) => {
    console.log("Create group")
    createGroupModalShow()
  };

  const onFinishHandlerCreateGroup = (values ) => {
    console.log(values)
    onFinishCreateGroup?.({
        name: values.group,
        color: values.color.toHex(),
    });
  }

  let [editGroupId, setEditGroupId] = React.useState("")

  // Edit group name Modal
  const {
      modalProps: editGroupModalProps,
      formProps: editGroupFormProps,
      show: editGroupModalShow,
      formLoading: editGroupFormLoading,
      onFinish: onFinishEditGroup,
      close: closeEditGroup,
  } = useModalForm({
      action: "update",
      syncWithLocation: true,
      resource:"groups",
      id: editGroupId,
      onMutationSuccess: (data, variables, context, isAutoSave) => {
        closeEditGroup();
        getGroups()
      },
  });
  
  const onFinishHandlerEditGroup = (values ) => {
    console.log(values)
    onFinishEditGroup?.({
        id: editGroupId,
        name: editGroupName,
        color: editGroupColor,
    });
  }

  let [isLoadingEditGroup, setIsLoadingEditGroup] = React.useState(false)
  let [editGroupColor, setEditGroupColor] = React.useState("")
  let [editGroupName, setEditGroupName] = React.useState("")

  const editGroup = async (value, record) => {
    console.log("Edit group")
    setEditGroupColor(prev => record.color)
    setEditGroupName(prev => record.name)
    setEditGroupId(prev => record.id)
    editGroupModalShow()
  };

  const handleGroupNameChange = async (e) => {
    setEditGroupName(e.target.value)
  };
  
  const handleGroupColorChange = async (e) => {
    console.log(e)
    setEditGroupColor(e.metaColor.toHex())
  };

  
  let [selectedChains, setSelectedChains] = React.useState(enabled_chains.map(a => a.value))

  const handleOnchangeEnabledChains = async (e) => {
    console.log(e)
    setSelectedChains(e)
  };
  
  let [language, setLanguage] = React.useState("english")

  let [settings, setSettings] = React.useState()

  async function fetchSettings() {
    console.log('fetching settings');
  
    let url
    if (qa_environment == "blue"){
        url = `${apiUrl}/management/blue/GET/${continent.toUpperCase()}/v0/settings`
    }  
    else{
      url = `${apiUrl}/management/GET/${continent.toUpperCase()}/v0/settings`
    }
    console.log(url)

    const requestHeaders = {
        Authorization: `Bearer ${token.__raw}`,
        Accept: "application/json, text/plain, */*",
        "Source-Platform": "dashboard",
        "Source-Region": use_region,
        "Destination-Region": use_region,
    };

    return fetch(url, {headers: requestHeaders})
      .then((response) => 
        response.json() 
      )
      .then((body) => {
        setSettings(body.result);
        setLanguage(body.result.language.value);
        setSelectedChains(body.result.enabled_chains.map(a => a.value));
      }
      );
  }

  let [isLoadingSettings, setIsLoadingSettings] = React.useState()

  const submitSettings = async (e) => {
    console.log("Get settings")
    setIsLoadingSettings(true)
  
    let url
    if (qa_environment == "blue"){
        url = `${apiUrl}/management/blue/ANY/${continent.toUpperCase()}/v0/settings`
    }  
    else{
      url = `${apiUrl}/management/ANY/${continent.toUpperCase()}/v0/settings`
    }
    console.log(url)
    
    const requestHeaders = {
      Authorization: `Bearer ${token.__raw}`,
      Accept: "application/json, text/plain, */*",
      "Source-Platform": "dashboard",
      "Source-Region": use_region,
      "Destination-Region": use_region,
  };

  const settings_obj = {
    "language": language,
    "enabled_chains": selectedChains,
  }

  console.log(settings_obj)
    const { data, status } = await axios.put(
      url, JSON.stringify(settings_obj), {headers: requestHeaders}
    ).catch(function (error) {
      console.log(error)
      setIsLoadingSettings(false)

      open?.({
        type: "error",
        message: "Error",
        description: error.response?.data?.error?.message,
        key: "settings",
      });
    })

    console.log("Settings updated: " + JSON.stringify(data))

  
    if(data?.error){
      setIsLoadingSettings(false)
    }
    else{
      open?.({
        type: "success",
        message: "Successfully updated the settings",
        description: "Successfully updated the settings",
        key: "tags",
      });
    }
    setIsLoadingSettings(false)
  };
  

  useEffect(() => {
    if(!settings){
      fetchSettings()
    } 

    if(tenants){
      localStorage.setItem(local_storage_prefix + '_tenants', JSON.stringify(tenants))
    } 

    if(organizationMembers){
      localStorage.setItem(local_storage_prefix + '_organization_members', JSON.stringify(organizationMembers))
    } 

    if(foundAddresses){
      localStorage.setItem(local_storage_prefix + '_my_addresses', JSON.stringify(foundAddresses))
    } 
    
    if(labels){
      localStorage.setItem(local_storage_prefix + '_labels', JSON.stringify(labels))
    } 
  
    if(tags){
      localStorage.setItem(local_storage_prefix + '_tags', JSON.stringify(tags))
    } 
   
    if(groups){
      localStorage.setItem(local_storage_prefix + '_groups', JSON.stringify(groups))
    } 
    
    if(sharedGraphs){
      localStorage.setItem(local_storage_prefix + '_shared_graphs', JSON.stringify(sharedGraphs))
    } 

  }, [settings, language, selectedChains, groups, labels, tags, tenants, organizationMembers, foundAddresses, sharedGraphs ]);

  return (
    <Show
    title="Settings"
    resource="posts"
    recordItemId={1}
    isLoading={false}
    headerProps={{
        backIcon: false,
    }}
    headerButtons={
        <Space
            key="action-buttons"
            style={{ float: "right", marginRight: 24 }}
        >
            <Button
                danger
                disabled={false}
                onClick={() =>
                    record && handleUpdate(record, "rejected")
                }
            >
                Reset All Tabs
            </Button>
            <Button
                type="primary"
                disabled={false}
                onClick={() =>
                    record && handleUpdate(record, "published")
                }
            >
                Save All Tabs
            </Button>
        </Space>
    }
>
  
    <Space>
      <Tabs defaultActiveKey="1" 
          tabPosition="top"
          style={{fontSize:18}}
        >



        <TabPane label="general" tab={<span style={{fontSize:18}}>General</span>} key="1">
        <Title level={5}>Selected Region</Title>
            {/* <Text> {user["https://authe.io/continent"]}</Text> */}
            <Text> 
              <Space wrap>
              <Tooltip title="Not allowed to change this after signup">
              <TextField disabled={true}
                                strong
                                value={ available_regions.get(user[process.env.REACT_APP_BASE_URL + "/continent"]) +  '(currently selected)'
                                }
                            />
              </Tooltip>
            </Space>          
            </Text> 

            <Title level={5}>Available Regions</Title>
            {/* <Text> {user["https://authe.io/continent"]}</Text> */}
            <Text> 
              <Space wrap>
              <Tooltip title="In which region your data is stored">
              <Select
                defaultValue={user[process.env.REACT_APP_BASE_URL + "/continent"]}
                style={{ width: 500 }}
                options={[
                  { value: "US", label: 'US - US West (Oregon) and US East (N. Virginia)'},
                  { value: "EU", label: 'EU - Europe (Frankfurt) and Europe (Ireland)' },
                  { value: "AP", label: 'AP - Asia Pacific (Tokyo) and Asia Pacific (Singapore)'  },
                  { value: "IN", label: 'IN - Asia Pacific (Mumbai) and Asia Pacific (Hyderabad)'  },
                ]}
              />
              </Tooltip>
            </Space>          
            </Text> 
            
            <Title level={5}>Language</Title>
            <Text> 
              <Space wrap>
              <Select
                value={language}
                style={{ width: 500 }}
                options={[
                  { value: "english", label: 'English' },
                ]}
              />
            </Space>          
            </Text> 
            <Title level={5}>Enabled Chains</Title>
            <Text> 
                  {
                      <Checkbox.Group
                        value={selectedChains}
                        options={enabled_chains}
                        onChange={(e) => handleOnchangeEnabledChains(e)}
                      />
                  }
            </Text>
            <Button type="primary" style={{marginTop:"10px"}} onClick={submitSettings} disabled={isLoadingSettings}>
              Save
          </Button>
        </TabPane>




        <TabPane label="profile" tab={<span style={{fontSize:18}}>Profile</span>} key="2">
            <Title level={5}>Profile Picture</Title>
            <Card
                hoverable
                style={{ width: 240 }}
                cover={
                    <img
                      alt="profile_picture"
                      style={{ height: "100%" }}
                      src={user?.picture}
                    />
                }
              >
            </Card>
            
            <Form
    name="complex-form"
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 16 }}
    style={{ maxWidth: 600}}
  >
    <Form.Item label="Id">
      <Space>
         <Form.Item
            name="id"
            noStyle
            rules={[{ required: true, message: 'Id is required' }]}
            initialValue={user.sub}
          >
            <Input style={{ width: 160 }} defaultValue={user.sub} disabled={true}/>
          </Form.Item>
      </Space>
    </Form.Item>
    <Form.Item label="Username">
      <Space>
        <Form.Item
          name="username"
          noStyle
          rules={[{ required: true, message: 'Username is required' }]}
          initialValue={user.nickname}
        >
          <Input style={{ width: 160 }} placeholder="Please input" defaultValue={user.nickname} disabled={true}/>
        </Form.Item>
      </Space>
    </Form.Item>
    <Form.Item label="Name">
      <Space>
        <Form.Item
          name="given_name"
          noStyle
          rules={[{ required: true, message: 'Name is required' }]}
          initialValue={user.given_name}
        >
          <Input style={{ width: 160 }} placeholder="Please input" defaultValue={user.given_name} disabled={!editGivenName}/>
        </Form.Item>
        <Typography.Link id="given_name" onClick={(e) => {{toggleEdit(e)}}} href="#">Edit</Typography.Link>
      </Space>
    </Form.Item>

    <Form.Item label="Lastname">
      <Space>
        <Form.Item
          name="last_name"
          noStyle
          rules={[{ required: true, message: 'Lastname is required' }]}
          initialValue={user.family_name}
        >
          <Input style={{ width: 160 }} placeholder="Please input" defaultValue={user.family_name} disabled={!editFamilyName}/>
        </Form.Item>
        <Typography.Link id="family_name" onClick={(e) => {{toggleEdit(e)}}} href="#">Edit</Typography.Link>
      </Space>
    </Form.Item>
    <Form.Item label="Email" style={{ marginBottom: 0 }}>
      <Form.Item
        name="email"
        rules={[{ required: true }]}
        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        initialValue={user.email}
        >
        <Input placeholder="Input email" defaultValue={user.email} disabled={true}/>
      </Form.Item>
    </Form.Item>
    <Form.Item label=" " colon={false}>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
           
  </TabPane>

        <TabPane label="permissions" tab={<span style={{fontSize:18}}>Roles and Permissions</span>} key="3">
            <Title level={5}>Current Role(s)</Title>

            <Tooltip title="Which role(s) you have in the application">
            <Text> 
              <Text   >
                    {
                          user[process.env.REACT_APP_BASE_URL + '/roles'].map(row=>{
                            return <Tag color={"geekblue"} key={row}>{row}</Tag>
                        })
                    }
                </Text>
            </Text>
            </Tooltip>

            <Title level={5}>Available Roles</Title>
            <Text > 
              <Space wrap>
              <Tooltip title="Which role(s) you have in the application">
              <Select
                defaultValue={user[process.env.REACT_APP_BASE_URL + "/roles"]}
                style={{ width: 500 }}
                mode="multiple"
                allowClear
                placeholder="Please select"
                onChange={handleSelectedRoleChange}
                options={availableRoles}
              />
              </Tooltip>
              <Typography.Link id="reload_roles" onClick={(e) => {{handleReloadRoles(e)}}} href="#" style={{margin:"10px"}}>Reload roles</Typography.Link>
              <Button id="save_role" type="primary" disabled={isLoadingSubmitRoleChange} onClick={handleSubmitdRoleChange} >Save</Button>
            </Space>          
            </Text>


            <Title level={5}>Combined Permissions currently attached</Title>
            <Text> 
              {/* <Tag color={"geekblue"}>{user['https://authe.io/roles']}</Tag> */}
              <Tag  color={"geekblue"} >
                    {
                        scopes.map(scope=>{
                          return <Form>
                          <Form.Item label="" name={scope} >
                              <Checkbox defaultChecked={true}>{scope}</Checkbox>
                          </Form.Item>
                        </Form>
                        })
                    }
                </Tag>
            </Text>


            <Title level={5}>Create a custom application role</Title>
            <Link to="/settings/roles/create"> <Button id="save_new_role" type="primary"  style={{margin:"10px"}} >Create a new Role</Button></Link>
        </TabPane>

        <TabPane label="organization" tab={<span style={{fontSize:18}}>Organization</span>} key="4">
        <Form
          // layout="vertical"
          name="organization-form"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 700, width: 700 }}
        >
          <Title level={5}>Organization details:</Title>
          <Form.Item label="Organization id:">
          <Space>
            <Form.Item
              label="Organization id"
              name="organization_id"
              noStyle
              rules={[{ required: true, message: 'Organization id is required' }]}
              initialValue={user[process.env.REACT_APP_BASE_URL + '/org_id']}
            >
              <Input id="organization_id" style={{ width: 325 }} value={user[process.env.REACT_APP_BASE_URL + '/org_id']}  defaultValue={user[process.env.REACT_APP_BASE_URL + '/org_id']} disabled={true}/>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Organization Name:">
          <Space>
            <Form.Item
              label="Organization Name"
              name="organization_name"
              noStyle
              rules={[{ required: true, message: 'Organization name is required' }]}
              initialValue={user[process.env.REACT_APP_BASE_URL + '/org']?.name}
            >
              <Input id="organization_name" style={{ width: 325 }} value={user[process.env.REACT_APP_BASE_URL + '/org']?.name} onChange={(e) => {{onOrganizationNamechange(e)}}} placeholder="Please input" defaultValue={user[process.env.REACT_APP_BASE_URL + '/org']?.name} disabled={!editOrganizationnName}/>
            </Form.Item>
            <Typography.Link id="organization_name" onClick={(e) => {{toggleEdit(e)}}} href="#">Edit</Typography.Link>
          </Space>
        </Form.Item>
        <Form.Item label=''>
              <Button id="organization" onClick={(e) => {{saveOrganizationDetailsButton(e)}}} type="primary" disabled={!enableOrganizationSaveButton}>Update</Button>
        </Form.Item>

        <Title level={5}>Tenants:</Title>
            <Text> 
                    {
                        tenants.map(tenant=>{
                          return <Form> 
                            <Form.Item label="Tenant Name:">
                              <Space>
                                <Form.Item
                                  label="Tenant Name"
                                  name={tenant.name}
                                  noStyle
                                  rules={[{ required: true, message: 'Tenant name is required' }]}
                                  initialValue={tenant.name}
                                >
                                  <Input id={tenant.name} style={{ width: 325 }} placeholder="" defaultValue={tenant.name} disabled={true}/>
                                  <Tooltip title="In which region your data is stored"> <Input id={tenant.continent} style={{ width: 45 }} placeholder="" defaultValue={tenant.continent} disabled={true}/></Tooltip>
                                  <Tooltip title="Tenant ID"> <Input id={tenant.id} style={{ width: 100 }} placeholder="" defaultValue={tenant.id} disabled={true}/></Tooltip>

                                </Form.Item>
                                <Typography.Link id={tenant.id} onClick={(e) => {{handleChangeTenant(e)}}} href="#">Switch</Typography.Link>
                                
                              </Space>
                            </Form.Item> 
                        </Form>
                        })
                    }
            </Text>
          <Button id="get_tenants" type="primary" onClick={getTenants} disabled={isLoadingGetTenants} >Get Tenants</Button>
          <Button id="create_tenant" type="default" onClick={createTenant} disabled={isLoadingCreateTenant} style={{margin:"10px"}} >Create Tenant</Button>
          <Modal {...createTenantModalProps} >
                <Spin spinning={createTenantFormLoading}>
                    <Form {...createTenantFormProps} layout="vertical" style={{ width: 500 }}>
                        <Form.Item
                            label="Tenant name"
                            name="name"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Tooltip title="In which region your data is stored">  
                          <Form.Item
                              label="Continent"
                              name="continent"
                              initialValue={user[process.env.REACT_APP_BASE_URL + "/continent"]}
                              rules={[
                                  {
                                      required: true,
                                  },
                              ]}
                          >
                        <Select
                          defaultValue={user[process.env.REACT_APP_BASE_URL + "/continent"]}
                          style={{ width: 500 }}
                          options={[
                            { value: "US", label: 'US - US West (Oregon) and US East (N. Virginia)'},
                            { value: "EU", label: 'EU - Europe (Frankfurt) and Europe (Ireland)' },
                            { value: "AP", label: 'AP - Asia Pacific (Tokyo) and Asia Pacific (Singapore)'  },
                            { value: "IN", label: 'IN - Asia Pacific (Mumbai) and Asia Pacific (Hyderabad)'  },
                          ]}
                        />
                          </Form.Item>
                        </Tooltip>
                    </Form>
                </Spin>
            </Modal>
<br></br>
<br></br>
<br></br>

        <Title level={5}>Organization members:</Title>
      </Form>
              <Table  rowKey="id" dataSource={organizationMembers}>
                  <Table.Column dataIndex="id" title="Id" />
                  <Table.Column dataIndex="name" title="Name" />
                  <Table.Column dataIndex="email" title="Email" />
                  <Table.Column dataIndex="roles" title="Role(s)" />
                  <Table.Column dataIndex="status" title="Status" />
                  <Table.Column
                    title="Actions"
                    dataIndex="id"
                    render={(_, id) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={id.id}
                                resource="permission-documents"
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId= {id.id}
                                resource="permission-documents"
                            />
                        </Space>
                    )}
                />
              </Table>
              <Button id="organization_members" type="primary" onClick={getOrganizationMembers} disabled={isLoadingGetOrganizationMembers} >Get members</Button>
              <Button id="invite_organization_members" type="default" onClick={inviteOrgMember} style={{margin:"10px"}}>Invite New members</Button>
              <Modal {...createInviteOrgMemberModalProps} >
                <Spin spinning={createInviteOrgMemberFormLoading}>
                    <Form {...createInviteOrgMemberFormProps} layout="vertical" style={{ width: 500 }}>
                        <Form.Item
                            label="User email"
                            name="email"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                    type: "email",
                                    message: "The input is not a valid E-mail address",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Tooltip title="Which role(s) the new user will have in the application">
                          <Form.Item
                              label="Roles"
                              name="roles"
                              value={[ "sandbox-Admin"]}
                              initialValue={[]}
                              rules={[
                                  {
                                      required: false,
                                  },
                              ]}
                          >
                        <Select
                            style={{ width: 500 }}
                            mode="multiple"
                            allowClear
                            placeholder="Please select"
                            // onChange={handleSelectedRoleChange}
                            options={availableRoles}
                        />
                          </Form.Item>
                        </Tooltip>
                        <Typography.Link id="reload_roles" onClick={(e) => {{handleReloadRoles(e)}}} href="#" style={{margin:"10px"}}>Reload roles</Typography.Link>
                        <Form.Item 
                            hidden={true}
                            label="Tenant Id"
                            name="tenant_id"
                            style={{ width: 500 }}
                            initialValue={user[process.env.REACT_APP_BASE_URL + "/tenant_id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input defaultValue={user[process.env.REACT_APP_BASE_URL + "/tenant_id"]}/>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </TabPane>
        
        <TabPane label="addresses" tab={<span style={{fontSize:18}}>Addresses</span>} key="5">
            <Table rowKey="id" dataSource={foundAddresses}>
                  {/* <Table.Column 
                    dataIndex="id" 
                    title="Id"   
                    render={(_, record) => (                            
                                    record?.id.substring(0, 10)
                  )}></Table.Column> */}
                  <Table.Column 
                      dataIndex="address" 
                      title="Address" 
                      render={(value, _) => 
                        <Tooltip title={_.notes}>{value}</Tooltip>
                      }                   
                  />
                  <Table.Column 
                    dataIndex="labels" 
                    title="Address labels" 
                    render={(value) => 
                      value?.map((label) => <Tag id={label.label} key={label.label} value={label.label} defaultValue="x" color={"#" + label.value}  style={{ marginTop: 5, marginBottom: 5 }}>{label.label}</Tag>)
                    } 
                  />
                  <Table.Column 
                    dataIndex="default_labels" 
                    title="Default labels" 
                    render={(value) => 
                      value?.map((label) => <Tooltip title="Labels that get attached to each transaction automatically"><Tag id={label.label} key={label.label} value={label.label} defaultValue="x" color={"#" + label.value}  style={{ marginTop: 5, marginBottom: 5 }}>{label.label}</Tag></Tooltip>)
                    } 
                  />                           
                  <Table.Column 
                      dataIndex="tags" 
                      title="Default Tags"
                      render={(value) => 
                        value?.map((tag) => <Tag id={tag.label} key={tag.label} value={tag.label} color={"#" + tag.value}  style={{ marginTop: 5, marginBottom: 5 }}>{tag.label}</Tag>)
                      } 
                    />
                  <Table.Column 
                    dataIndex="created_by" 
                    title="Created by user" 
                  />
                  <Table.Column dataIndex="created_at" title="Created at" render={(value) =>
                    <DateField value={dayjs.unix(value)} format="LLL"></DateField>
                  } />
                  <Table.Column 
                    dataIndex="shared_with" 
                    title="Shared with users" 
                    key="shared_with"
                    render={(obj) => 
                      {
                        if(obj){
                          let users = []
                          for (const [key, value] of Object.entries(obj)) {
                            // console.log(`${key}: ${value}`);
                            users.push(<Tag id={value} key={value} value={value} style={{ marginTop: 5, marginBottom: 5 }}>{value}</Tag>)

                          }
                          return users
                        }
                      }
                     
                    } 
                  />
                  {/* <Table.Column dataIndex="org_id" title="Org_id" /> */}
                  {/* <Table.Column dataIndex="tenant_id" title="Tenant_id" /> */}
                  <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                resource="addresses"
                            />
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                resource="addresses"
                            />
                            <DeleteButton
                                size="small"
                                hideText
                                recordItemId={record.id}
                                resource="addresses"
                                onSuccess={(value) => {
                                  getAddresses()
                                }}
                            />
                        </Space>
                    )}
                />
              </Table>
              <Button id="get_tenant_addresses" type="primary" onClick={getTenantAddresses} disabled={isLoadingGetTenantAddresses} style={{margin:"10px"}}>Get All Tenant addresses</Button>
              <Button id="get_user_addresses" type="primary" onClick={getUserAddresses} disabled={isLoadingGetUserAddresses} style={{margin:"10px"}}>Get my addresses</Button>
              <Button id="add_address" type="default" onClick={addAddress} style={{margin:"10px"}}>Add adddress</Button>
              <Modal {...addAddressModalProps} centered>
                <Spin spinning={addAddressFormLoading}>
                    <Form {...addAddressFormProps} layout="vertical" style={{ width: 500 }}>
                        <Form.Item
                            label="Address"
                            name="address"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                  validator: async (_, value) => {
                                      if (!value) return;
                                      try{
                                        if(isAddress(value)){
                                          return Promise.resolve();
                                        }
                                        else{
                                          throw new Error("'Not a valid address");
                                        }
                                    }
                                      catch{
                                          return Promise.reject(
                                              new Error("'Not a valid address"),
                                          );
                                      }
                                  },
                              },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </TabPane>

        <TabPane label="advanced" tab={<span style={{fontSize:18}}>Advanced</span>} key="6">
            <Title level={5}>API Keys</Title>
            <Form >
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label='API_KEY_ID'>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
              <Col span={8}>
                  <Form.Item label=''>
                    <Button type="primary">Generate a new key</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Title level={5}>Bring your own database</Title>
            <Form >
              <Row gutter={2}>
                <Col span={8}>
                  <Form.Item label='Database name and endpoint'>
                    <Input disabled={true} value="https://your_endpoint_here" style={{width:"200px"}}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
              <Col span={8}>
                  <Form.Item label=''>
                    <Button type="primary">Save</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
        </TabPane>

        <TabPane label="tags" tab={<span style={{fontSize:18}}>Tags</span>} key="7">
            <Table rowKey="id" dataSource={tags}>
                  <Table.Column 
                      filters={tags.map((
                        { name, color }) => ({ "text": name, "value": name}))}
                      filterMode='tree'
                      filterSearch={true}
                      onFilter={(value, record) => record.name.startsWith(value)}
                      dataIndex="name" 
                      title="Name"  
                      render={(_, tag) => 
                        <Tag id={tag.name} key={tag.name} value={tag.name} color={"#" + tag.color}  style={{ marginTop: 5, marginBottom: 5 }}>{tag.name}</Tag>
                      } />
                  <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                resource="tags"
                                onClick={(e) => 
                                  editTag(_, record)
                                  
                                }
                            />
                            <DeleteButton
                                size="small"
                                hideText
                                recordItemId={record.id}
                                resource="tags"
                                onSuccess={(value) => {
                                  getTags()
                                }}
                            />
                        </Space>
                    )}
                />
              </Table>
              <Button id="get_tags" type="primary" onClick={getTags} disabled={isLoadingGetTags} style={{margin:"10px"}}>Get tags</Button>
              <Button id="add_tag" type="default" onClick={createTag} style={{margin:"10px"}}>Add tag</Button>
              <Modal {...createTagModalProps} centered>
                <Spin spinning={createTagFormLoading}>
                    <Form {...createTagFormProps} layout="vertical" style={{ width: 500 }} onFinish={onFinishHandlerCreateTag}>
                        <Form.Item
                            label="Tag name"
                            name="tag"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Color"
                            name="color"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                      <ColorPicker
                        format="hex"
                        styles={{
                          popupOverlayInner: {
                            width: 468 + 24,
                          },
                        }}
                        presets={[
                          {
                            label: 'Recommended',
                            colors: [
                              '#000000',
                              '#000000E0',
                              '#000000A6',
                              '#00000073',
                              '#00000040',
                              '#00000026',
                              '#0000001A',
                              '#00000012',
                              '#0000000A',
                              '#00000005',
                              '#F5222D',
                              '#FA8C16',
                              '#FADB14',
                              '#8BBB11',
                              '#52C41A',
                              '#13A8A8',
                              '#1677FF',
                              '#2F54EB',
                              '#722ED1',
                              '#EB2F96',
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                              '#1677FF4D',
                              '#2F54EB4D',
                              '#722ED14D',
                              '#EB2F964D',
                            ],
                          },
                          {
                            label: 'Recent',
                            colors: [
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                            ],
                          },
                        ]}
                        panelRender={(_, { components: { Picker, Presets } }) => (
                          <div
                            className="custom-panel"
                            style={{
                              display: 'flex',
                              width: 468,
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                              }}
                            >
                              <Presets />
                            </div>
                            <Divider
                              type="vertical"
                              style={{
                                height: 'auto',
                              }}
                            />
                            <div
                              style={{
                                width: 234,
                              }}
                            >
                              <Picker />
                            </div>
                          </div>
                        )}
                      />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <Modal {...editTagModalProps} centered destroyOnClose>
                <Spin spinning={editTagFormLoading}>
                    <Form {...editTagFormProps} layout="vertical" style={{ width: 500 }} onFinish={onFinishHandlerEditTag}>
                        <Form.Item
                            label="Tag name"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input value={editTagName} onChange={handleTagNameChange}></Input>
                        </Form.Item>
                        <Form.Item
                            label="Color"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                      <ColorPicker
                        onChange={handleTagColorChange}
                        format="hex"
                        value={editTagColor}
                        styles={{
                          popupOverlayInner: {
                            width: 468 + 24,
                          },
                        }}
                        presets={[
                          {
                            label: 'Recommended',
                            colors: [
                              '#000000',
                              '#000000E0',
                              '#000000A6',
                              '#00000073',
                              '#00000040',
                              '#00000026',
                              '#0000001A',
                              '#00000012',
                              '#0000000A',
                              '#00000005',
                              '#F5222D',
                              '#FA8C16',
                              '#FADB14',
                              '#8BBB11',
                              '#52C41A',
                              '#13A8A8',
                              '#1677FF',
                              '#2F54EB',
                              '#722ED1',
                              '#EB2F96',
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                              '#1677FF4D',
                              '#2F54EB4D',
                              '#722ED14D',
                              '#EB2F964D',
                            ],
                          },
                          {
                            label: 'Recent',
                            colors: [
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                            ],
                          },
                        ]}
                        panelRender={(_, { components: { Picker, Presets } }) => (
                          <div
                            className="custom-panel"
                            style={{
                              display: 'flex',
                              width: 468,
                              color: 'rgba(19, 168, 168, 0.88)',
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                              }}
                            >
                              <Presets />
                            </div>
                            <Divider
                              type="vertical"
                              style={{
                                height: 'auto',
                              }}
                            />
                            <div
                              style={{
                                width: 234,
                              }}
                            >
                              <Picker />
                            </div>
                          </div>
                        )}
                      />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </TabPane>

        <TabPane label="labels" tab={<span style={{fontSize:18}}>Labels</span>} key="8">
            <Table rowKey="id" dataSource={labels} 
                  pagination={{
                      defaultPageSize:10,
                      showSizeChanger:true
                  }}>
                  <Table.Column 
                      dataIndex="name" 
                      title="Name"  
                      render={(_, label) => 
                        <Tag id={label.name} key={label.name} value={label.name} color={"#" + label.color}  style={{ marginTop: 5, marginBottom: 5 }}>{label.name}</Tag>
                      } />
                  <Table.Column 
                      dataIndex="address" 
                      title="Is Address Label"  
                      render={(_, label) => 
                          label.address? <Tag id={label.id} key={label.id} value={label.id} color={"#" + label.color}  style={{ marginTop: 5, marginBottom: 5 }}>{label.address}</Tag> :  ""
                      } />
                  <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                resource="labels"
                                onClick={(e) => 
                                  editLabel(_, record)
                                  
                                }
                            />
                            <DeleteButton
                                size="small"
                                hideText
                                recordItemId={record.id}
                                resource="labels"
                                onSuccess={(value) => {
                                  getLabels()
                                }}
                            />
                        </Space>
                    )}
                />
              </Table>
              <Button id="get_labels" type="primary" onClick={getLabels} disabled={isLoadingGetLabels} style={{margin:"10px"}}>Get Labels</Button>
              <Button id="add_label" type="default" onClick={createLabel} style={{margin:"10px"}}>Add Label</Button>
              <Modal {...createLabelModalProps} centered>
                <Spin spinning={createLabelFormLoading}>
                    <Form {...createLabelFormProps} layout="vertical" style={{ width: 500 }} onFinish={onFinishHandlerCreateLabel}>
                        <Form.Item
                            label="Label name"
                            name="label"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Color"
                            name="color"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                      <ColorPicker
                        format="hex"
                        styles={{
                          popupOverlayInner: {
                            width: 468 + 24,
                          },
                        }}
                        presets={[
                          {
                            label: 'Recommended',
                            colors: [
                              '#000000',
                              '#000000E0',
                              '#000000A6',
                              '#00000073',
                              '#00000040',
                              '#00000026',
                              '#0000001A',
                              '#00000012',
                              '#0000000A',
                              '#00000005',
                              '#F5222D',
                              '#FA8C16',
                              '#FADB14',
                              '#8BBB11',
                              '#52C41A',
                              '#13A8A8',
                              '#1677FF',
                              '#2F54EB',
                              '#722ED1',
                              '#EB2F96',
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                              '#1677FF4D',
                              '#2F54EB4D',
                              '#722ED14D',
                              '#EB2F964D',
                            ],
                          },
                          {
                            label: 'Recent',
                            colors: [
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                            ],
                          },
                        ]}
                        panelRender={(_, { components: { Picker, Presets } }) => (
                          <div
                            className="custom-panel"
                            style={{
                              display: 'flex',
                              width: 468,
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                              }}
                            >
                              <Presets />
                            </div>
                            <Divider
                              type="vertical"
                              style={{
                                height: 'auto',
                              }}
                            />
                            <div
                              style={{
                                width: 234,
                              }}
                            >
                              <Picker />
                            </div>
                          </div>
                        )}
                      />
                        </Form.Item>
                        <Tooltip title="Whether the label is for an address or not">
                        <Form.Item
                            label="Is static address label"
                            name="is_address_label"
                            style={{ width: 500 }}
                            checked={showAddressLabelFields}
                            rules={[
                                {
                                    required: false,
                                }
                            ]}
                        >
                            <Checkbox onClick={(e) => setShowAddressLabelFields(e.target.checked)}/>
                        </Form.Item>
                        </Tooltip>
                        <Tooltip title="The label will be applied for this address?">
                        <Form.Item
                            hidden={!showAddressLabelFields}
                            label="Address"
                            name="address"
                            style={{ width: 500 }}
                            value={labelAddress}
                            rules={[
                                {
                                    required: showAddressLabelFields ? true : false,
                                }
                            ]}
                        >
                          
                            <Input onChange={(e) => setLabelAddress(e.target.value)} initialValue={labelAddress} placeholder="0x"/>                      
                        </Form.Item>
                        </Tooltip>
                        <Tooltip title="For which chain(s)?">
                        <Form.Item
                            hidden={!showAddressLabelFields}
                            label="Show label on Chain(s)"
                            name="chains"
                            value={labelChains}
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: showAddressLabelFields ? true : false,
                                }
                            ]}
                        >
                        <Select
                          style={{ width: 500 }}
                          mode="multiple"
                          options={enabled_chains}
                          onChange={setLabelChains} 
                          initialValue={labelChains}     
                          defaultValue={labelChains}
                        />
                        </Form.Item>
                        </Tooltip>
                        <Tooltip title="If there are multiple labels the main label will be displayed in the charts">
                        <Form.Item
                            hidden={!showAddressLabelFields}
                            label="Is main label for this address and chain?"
                            name="is_main_label"
                            style={{ width: 500 }}
                            checked={isMainAddressLabel}
                            rules={[
                                {
                                    required: false,
                                }
                            ]}
                        >
                            <Checkbox onClick={(e) => setIsMainAddressLabel(e.target.checked)}/>
                        </Form.Item>
                        </Tooltip>
                        <Tooltip title="For which chain(s)?">
                        <Form.Item
                            hidden={!showAddressLabelFields || !isMainAddressLabel}
                            label="Main label on Chain(s)"
                            name="main_label_chains"
                            value={mainLabelChains}
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: showAddressLabelFields && isMainAddressLabel ? true : false,
                                }
                            ]}
                        >
                        <Select
                          style={{ width: 500 }}
                          mode="multiple"
                          options={enabled_chains}
                          onChange={setMainLabelChains} 
                          initialValue={mainLabelChains}     
                          defaultValue={mainLabelChains}
                        />
                        </Form.Item>
                        </Tooltip>

                        <Form.Item label="Image" hidden={!showAddressLabelFields}>
                          <Form.Item
                            hidden={!showAddressLabelFields}
                            name="image"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                            noStyle
                          >
                            <Upload.Dragger source name="file" action={`https://us.api.sandbox.authe.io/private/labels/blue/ANY/US/v0/labels/upload-image`} headers={requestHeaders}  
                              listType="picture" maxCount={1} multiple>
                              <p className="ant-upload-text">Drag & drop a file in this area</p>
                            </Upload.Dragger>
                          </Form.Item>
                      </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <Modal {...editLabelModalProps} centered destroyOnClose>
                <Spin spinning={editLabelFormLoading}>
                    <Form {...editLabelFormProps} layout="vertical" style={{ width: 500 }} onFinish={onFinishHandlerEditLabel}>
                        <Form.Item
                            label="Label name"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input value={editLabelName} onChange={handleLabelNameChange}></Input>
                        </Form.Item>
                        <Form.Item
                            label="Color"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                      <ColorPicker
                        onChange={handleLabelColorChange}
                        format="hex"
                        value={editLabelColor}
                        styles={{
                          popupOverlayInner: {
                            width: 468 + 24,
                          },
                        }}
                        presets={[
                          {
                            label: 'Recommended',
                            colors: [
                              '#000000',
                              '#000000E0',
                              '#000000A6',
                              '#00000073',
                              '#00000040',
                              '#00000026',
                              '#0000001A',
                              '#00000012',
                              '#0000000A',
                              '#00000005',
                              '#F5222D',
                              '#FA8C16',
                              '#FADB14',
                              '#8BBB11',
                              '#52C41A',
                              '#13A8A8',
                              '#1677FF',
                              '#2F54EB',
                              '#722ED1',
                              '#EB2F96',
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                              '#1677FF4D',
                              '#2F54EB4D',
                              '#722ED14D',
                              '#EB2F964D',
                            ],
                          },
                          {
                            label: 'Recent',
                            colors: [
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                            ],
                          },
                        ]}
                        panelRender={(_, { components: { Picker, Presets } }) => (
                          <div
                            className="custom-panel"
                            style={{
                              display: 'flex',
                              width: 468,
                              color: 'rgba(19, 168, 168, 0.88)',
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                              }}
                            >
                              <Presets />
                            </div>
                            <Divider
                              type="vertical"
                              style={{
                                height: 'auto',
                              }}
                            />
                            <div
                              style={{
                                width: 234,
                              }}
                            >
                              <Picker />
                            </div>
                          </div>
                        )}
                      />
                        </Form.Item>
                        <Tooltip title="Whether the label is for an address or not">
                        <Form.Item
                            label="Is static address label"
                            name="is_address_label"
                            style={{ width: 500 }}
                            checked={showAddressLabelFields}
                            rules={[
                                {
                                    required: false,
                                }
                            ]}
                        >
                            <Checkbox disabled={true} checked={editIsAddressLabel}/>
                        </Form.Item>
                        </Tooltip>
                        <Tooltip title="The label will be applied for this address?">
                        <Form.Item
                            hidden={!editIsAddressLabel}
                            label="Address"
                            name="address"
                            style={{ width: 500 }}
                            value={editAddressLabel}
                            rules={[
                                {
                                    required: editIsAddressLabel ? false : false,
                                }
                            ]}
                        >
                          
                            <Input disabled={true} defaultValue={editAddressLabel}/>                      
                        </Form.Item>
                        </Tooltip>
                        <Tooltip title="For which chain(s)?">
                        <Form.Item
                            hidden={!editIsAddressLabel}
                            label="Show label on Chain(s)"
                            name="chains"
                            value={editLabelChains}
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: editIsAddressLabel ? false : false,
                                }
                            ]}
                        >
                        <Select
                          style={{ width: 500 }}
                          mode="multiple"
                          options={enabled_chains}
                          onChange={setLabelChains} 
                          defaultValue={editLabelChains?.map(item => item.chain_id)}     
                          disabled={true}
                        />
                        </Form.Item>
                        </Tooltip>                        
                        <Tooltip title="If there are multiple labels the main label will be displayed in the charts">
                        <Form.Item
                            hidden={!editIsAddressLabel}
                            label="Is main label for this address and chain?"
                            name="is_main_label"
                            style={{ width: 500 }}
                            checked={isMainAddressLabel}
                            rules={[
                                {
                                    required: false,
                                }
                            ]}
                        >
                            <Checkbox disabled={true} checked={editLabelIsMainAddressLabel}/>      
                        </Form.Item>
                        </Tooltip>                      
                        <Tooltip title="For which chain(s)?">
                        <Form.Item
                            hidden={!editIsAddressLabel || !editLabelIsMainAddressLabel}
                            label="Main label on Chain(s)"
                            name="main_label_chains"
                            value={editMainLabelChains}
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: editIsAddressLabel && editLabelIsMainAddressLabel? false : false,
                                }
                            ]}
                        >
                        <Select
                          style={{ width: 500 }}
                          mode="multiple"
                          options={enabled_chains}
                          onChange={setMainLabelChains} 
                          defaultValue={editMainLabelChains?.map(item => item.chain_id)}     
                          disabled={true}
                        />
                        </Form.Item>
                        </Tooltip>
                        <Form.Item
                            hidden={!editIsAddressLabel}
                            label="Current Image"
                            name="source"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: false,
                                }
                            ]}
                        >
                            <Avatar size="large" src={editLabelSource} />
                        </Form.Item>
                        <Form.Item label="Image" hidden={!editIsAddressLabel}>
                          <Form.Item
                            hidden={!editIsAddressLabel}
                            name="image"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                            noStyle
                          >
                            <Upload.Dragger name="file" action={`https://us.api.sandbox.authe.io/private/labels/blue/ANY/US/v0/labels/upload-image`} headers={requestHeaders}  
                              listType="picture" maxCount={1} multiple>
                              <p className="ant-upload-text">Drag & drop a file in this area</p>
                            </Upload.Dragger>
                          </Form.Item>
                      </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </TabPane>

        <TabPane label="groups" tab={<span style={{fontSize:18}}>Groups</span>} key="9">
            <Table rowKey="id" dataSource={groups}>
                  <Table.Column 
                      dataIndex="name" 
                      title="Name"  
                      render={(_, group) => 
                        <Tag id={group.name} key={group.name} value={group.name} color={"#" + group.color}  style={{ marginTop: 5, marginBottom: 5 }}>{group.name}</Tag>
                      } />
                  <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                resource="groups"
                                onClick={(e) => 
                                  editGroup(_, record)
                                  
                                }
                            />
                            <DeleteButton
                                size="small"
                                hideText
                                recordItemId={record.id}
                                resource="groups"
                                onSuccess={(value) => {
                                  getGroups()
                                }}
                            />
                        </Space>
                    )}
                />
              </Table>
              <Button id="get_groups" type="primary" onClick={getGroups} disabled={isLoadingGetGroups} style={{margin:"10px"}}>Get Groups</Button>
              <Button id="add_group" type="default" onClick={createGroup} style={{margin:"10px"}}>Add Group</Button>
              <Modal {...createGroupModalProps} centered>
                <Spin spinning={createGroupFormLoading}>
                    <Form {...createGroupFormProps} layout="vertical" style={{ width: 500 }} onFinish={onFinishHandlerCreateGroup}>
                        <Form.Item
                            label="Group name"
                            name="group"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Color"
                            name="color"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                      <ColorPicker
                        format="hex"
                        styles={{
                          popupOverlayInner: {
                            width: 468 + 24,
                          },
                        }}
                        presets={[
                          {
                            label: 'Recommended',
                            colors: [
                              '#000000',
                              '#000000E0',
                              '#000000A6',
                              '#00000073',
                              '#00000040',
                              '#00000026',
                              '#0000001A',
                              '#00000012',
                              '#0000000A',
                              '#00000005',
                              '#F5222D',
                              '#FA8C16',
                              '#FADB14',
                              '#8BBB11',
                              '#52C41A',
                              '#13A8A8',
                              '#1677FF',
                              '#2F54EB',
                              '#722ED1',
                              '#EB2F96',
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                              '#1677FF4D',
                              '#2F54EB4D',
                              '#722ED14D',
                              '#EB2F964D',
                            ],
                          },
                          {
                            label: 'Recent',
                            colors: [
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                            ],
                          },
                        ]}
                        panelRender={(_, { components: { Picker, Presets } }) => (
                          <div
                            className="custom-panel"
                            style={{
                              display: 'flex',
                              width: 468,
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                              }}
                            >
                              <Presets />
                            </div>
                            <Divider
                              type="vertical"
                              style={{
                                height: 'auto',
                              }}
                            />
                            <div
                              style={{
                                width: 234,
                              }}
                            >
                              <Picker />
                            </div>
                          </div>
                        )}
                      />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <Modal {...editGroupModalProps} centered destroyOnClose>
                <Spin spinning={editGroupFormLoading}>
                    <Form {...editGroupFormProps} layout="vertical" style={{ width: 500 }} onFinish={onFinishHandlerEditGroup}>
                        <Form.Item
                            label="Group name"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input value={editGroupName} onChange={handleGroupNameChange}></Input>
                        </Form.Item>
                        <Form.Item
                            label="Color"
                            style={{ width: 500 }}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                      <ColorPicker
                        onChange={handleGroupColorChange}
                        format="hex"
                        value={editGroupColor}
                        styles={{
                          popupOverlayInner: {
                            width: 468 + 24,
                          },
                        }}
                        presets={[
                          {
                            label: 'Recommended',
                            colors: [
                              '#000000',
                              '#000000E0',
                              '#000000A6',
                              '#00000073',
                              '#00000040',
                              '#00000026',
                              '#0000001A',
                              '#00000012',
                              '#0000000A',
                              '#00000005',
                              '#F5222D',
                              '#FA8C16',
                              '#FADB14',
                              '#8BBB11',
                              '#52C41A',
                              '#13A8A8',
                              '#1677FF',
                              '#2F54EB',
                              '#722ED1',
                              '#EB2F96',
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                              '#1677FF4D',
                              '#2F54EB4D',
                              '#722ED14D',
                              '#EB2F964D',
                            ],
                          },
                          {
                            label: 'Recent',
                            colors: [
                              '#F5222D4D',
                              '#FA8C164D',
                              '#FADB144D',
                              '#8BBB114D',
                              '#52C41A4D',
                              '#13A8A84D',
                            ],
                          },
                        ]}
                        panelRender={(_, { components: { Picker, Presets } }) => (
                          <div
                            className="custom-panel"
                            style={{
                              display: 'flex',
                              width: 468,
                              color: 'rgba(19, 168, 168, 0.88)',
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                              }}
                            >
                              <Presets />
                            </div>
                            <Divider
                              type="vertical"
                              style={{
                                height: 'auto',
                              }}
                            />
                            <div
                              style={{
                                width: 234,
                              }}
                            >
                              <Picker />
                            </div>
                          </div>
                        )}
                      />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </TabPane>

        <TabPane label="shared_graphs" tab={<span style={{fontSize:18}}>Shared Graphs</span>} key="10">
        <Table rowKey="id" dataSource={sharedGraphs}>
                  <Table.Column 
                      dataIndex="name" 
                      title="Name"  
                      render={(_, shared_graph) => 
                        <Tag id={shared_graph.name} key={shared_graph.name} value={shared_graph.name} style={{ marginTop: 5, marginBottom: 5 }}>{shared_graph.name}</Tag>
                      } />
                  <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                resource="shared-graphs"
                            />
                            <ShowButton
                                size="small"
                                hideText
                                recordItemId={record.id}
                                resource="shared-graphs"
                            />
                            <DeleteButton
                                size="small"
                                hideText
                                recordItemId={record.id}
                                resource="shared-graphs"
                                onSuccess={(value) => {
                                  getSharedGraphs()
                                }}
                            />
                        </Space>
                    )}
                />
              </Table>
              <Button id="get_shared_graphs" type="primary" onClick={getSharedGraphs} disabled={isLoadingGetSharedGraphs} style={{margin:"10px"}}>Get Shared Graphs</Button>
        </TabPane>

        {/* The UI breaks otherwise */}
        <TabPane label="empty" tab={<span style={{fontSize:18}}></span>} key="11">
        </TabPane>
    </Tabs>
  </Space>
</Show>
  );
}


